/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import PageTitle from "../../../components/Layout/PageTitle";
import Table from "../Components/table/Table";
import CreatePoi from "./components/CreatePoi";
import { PoisTable } from "../../../constants";
import { CircularProgress } from "@mui/material";
import { Circle } from "../../Map/components/DrawingComponents/circle";
import { useWindowDimensions } from "../../../utils/GetDimensions";
import plusIcon from "../../../assets/icons/Panel/administration/Assets/plus-icon.svg";
import fileIcon from "../../../assets/icons/Panel/administration/Assets/file-icon.svg";
import funnelIcon from "../../../assets/icons/Panel/administration/Assets/funnel-icon.svg";
import discardIcon from "../../../assets/icons/Panel/administration/Assets/discardIcon.svg";
import editIconWhite from "../../../assets/icons/Panel/administration/AssetGroup/editIconWhite.svg";
import ReusableBtn from "../../../components/ReusableBtn/ReusableBtn";

const bodyData = [
  {
    id: 0,
    data: [
      {
        id: 0,
        row: "ADC Data Center",
      },
      {
        id: 1,
        row: "Datacenter",
      },
      {
        id: 2,
        row: "ALB",
      },
      {
        id: 3,
        row: "41.2808668, 19.8614988",
      },
      {
        id: 4,
        row: "-",
      },
      {
        id: 5,
        row: "jurgen.hoxholli@adc.al",
      },
      {
        id: 6,
        row: "13/01/23 13:02",
      },
    ],
  },
];
const INITIAL_CENTER = { lat: 41.327545, lng: 19.818699 };

const Pois = () => {
  const [center, setCenter] = useState(INITIAL_CENTER);
  const [radius, setRadius] = useState(2300);
  const [mapType, setMapType] = useState("roadmap");
  const [mapIsReady, setMapIsReady] = useState(false);
  const { width } = useWindowDimensions();
  const mapRef = useRef();

  // eslint-disable-next-line no-unused-vars
  const [rowData, setRowData] = useState([]);

  const [view, setView] = useState("table");
  const [formData, setFormData] = useState({
    name: "",
    setType: "",
    assignToGroup: "",
    country: "",
    registeredAddress: "",
    coordinates: "",
    radius: true,
    // meters: 2300,
    drawAShape: false,
  });

  console.log("this is rowData:", rowData);

  console.log("this is formData:", formData);

  useEffect(() => {
    if (view === "view" && rowData.length > 0) {
      setFormData({
        name: rowData[0]?.row || "",
        setType: rowData[1]?.row || "",
        assignToGroup: rowData[0]?.row || "",
        country: rowData[2]?.row || "",
        registeredAddress: rowData[0]?.row || "",
        coordinates: rowData[3]?.row || "",
        radius: true,
        drawAShape: false,
      });
    }
  }, [view, rowData]);

  const handleTilesLoaded = useCallback(() => setMapIsReady(true), []);
  const onLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const changeCenter = (newCenter) => {
    if (!newCenter) return;
    setCenter({ lng: newCenter.lng(), lat: newCenter.lat() });
  };

  const handleChange = (event) => {
    const { id, name } = event.target;

    if (id === "radius" || id === "drawAShape") {
      setFormData((prev) => ({ ...prev, [id]: !formData[id] }));
    } else {
      setFormData((prev) => ({ ...prev, [id]: event.target.value }));
    }
  };

  const onSubmit = () => {
    console.log("this is formData:", formData, " this is radius:", radius);
  };

  return (
    <div
      className={`pageLayout ${
        (view === "create" || view === "view") &&
        "flex flex-col xl:flex-row bg-white p-0"
      }`}
    >
      <div
        className={`${
          view === "create" || view === "view"
            ? "w-full xl:w-1/2 px-5"
            : "w-full"
        }`}
      >
        <div
          className={`w-full flex flex-col gap-2 lg:gap-0 lg:flex-row lg:justify-between lg:items-center ${
            view === "create" || view === "view" ? "bg-white" : "bg-mainBg"
          } pt-5 z-10`}
        >
          <PageTitle
            titleLngId={
              view === "create"
                ? "pois.createNewPoi"
                : view === "view"
                ? rowData[0]?.row
                : "pois.pageTitle"
            }
            subTitleLngId={"app.administrationPanel"}
            subTitle2LngId={"pois.subtitle2"}
            state={view}
            setState={() => setView("table")}
          ></PageTitle>

          <div className=" flex items-center gap-2 mb-4 lg:mb-0">
            {(view === "create" || view === "view") && (
              <ReusableBtn
                icon={discardIcon}
                text={"assets.createAssetBtn1"}
                mainStyles={
                  "w-[100px] h-[45px] bg-transparent gap-2 px-1 border border-[#0D0D0D] rounded-md"
                }
                textStyles={"text-filterBtn text-[#0D0D0D]"}
                handleClick={() => setView("table")}
              />
            )}

            <ReusableBtn
              icon={
                view === "create"
                  ? ""
                  : view === "view"
                  ? editIconWhite
                  : plusIcon
              }
              imgWidth={"14px"}
              text={
                view === "create" || view === "table"
                  ? "pois.btn1"
                  : "assetGroup.assetGroupDetailsBtn1"
              }
              mainStyles={"w-fit h-[45px] bg-redPrimary gap-2 px-4 rounded-md"}
              textStyles={"text-filterBtn text-[#FFFFFF]"}
              handleClick={
                view === "create"
                  ? onSubmit
                  : view === "view"
                  ? () => console.log("edit page")
                  : () => setView("create")
              }
            />

            {view === "table" && (
              <>
                <ReusableBtn
                  icon={fileIcon}
                  mainStyles={" h-[45px] bg-white px-3 rounded-md"}
                  handleClick={() => console.log("")}
                />

                <ReusableBtn
                  text={"(81)"}
                  icon={funnelIcon}
                  mainStyles={"h-[45px] bg-white gap-1 px-3 rounded-md"}
                  textStyles={"text-textSecondary"}
                  handleClick={() => console.log("")}
                />
              </>
            )}
          </div>
        </div>

        {(view === "create" || view === "view") && (
          <CreatePoi
            radius={radius}
            setRadius={setRadius}
            formData={formData}
            handleChange={handleChange}
            setFormData={setFormData}
            view={view}
          />
        )}

        {view === "view" && <div></div>}

        {view === "edit" && <div>Edit</div>}

        {view === "table" && (
          <Table
            version={1}
            headerData={PoisTable}
            bodyData={bodyData}
            setState={setView}
            setRowData={setRowData}
          />
        )}
      </div>

      {(view === "create" || view === "view") && (
        <div className="w-full xl:w-1/2 h-full relative ">
          <APIProvider
            // eslint-disable-next-line no-undef
            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
            libraries={["places"]}
          >
            <Map
              style={{ height: width < 1280 ? "50vh" : "100%" }}
              onTilesLoaded={handleTilesLoaded}
              mapId={"bc6ecf2d30222d6d"}
              mapTypeId={mapType}
              defaultCenter={INITIAL_CENTER}
              defaultZoom={width > 640 ? 10 : 9}
              gestureHandling={"greedy"}
              disableDefaultUI={true}
              onLoad={onLoad}
            >
              <Marker
                position={center}
                draggable
                onDrag={(event) =>
                  setCenter({
                    lat: event.latLng?.lat() ?? 0,
                    lng: event.latLng?.lng() ?? 0,
                  })
                }
              />
              <Circle
                radius={radius}
                center={center}
                onRadiusChanged={setRadius}
                onCenterChanged={changeCenter}
                strokeColor={"#E60000"}
                strokeOpacity={0.8}
                strokeWeight={3}
                fillColor={"rgba(230, 0, 0, 0.5)"}
                fillOpacity={0.3}
                editable
                draggable
              />
            </Map>
            {!mapIsReady && (
              <div className="loading-overlay">
                <CircularProgress style={{ color: "#E60000" }} />
              </div>
            )}
          </APIProvider>
        </div>
      )}
    </div>
  );
};

export default Pois;
