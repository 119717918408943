/* eslint-disable complexity */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import ViewAsset from "../Components/ViewAsset/ViewAsset";
import CreateAsset from "./Components/CreateAsset";
import Table from "../Components/table/Table";
import PageTitle from "../../../components/Layout/PageTitle";
import ReusableBtn from "../../../components/ReusableBtn/ReusableBtn";
import { AssetDetailTitles, AssetsTable } from "../../../constants";
import plusIcon from "../../../assets/icons/Panel/administration/Assets/plus-icon.svg";
import fileIcon from "../../../assets/icons/Panel/administration/Assets/file-icon.svg";
import funnelIcon from "../../../assets/icons/Panel/administration/Assets/funnel-icon.svg";
import discardIcon from "../../../assets/icons/Panel/administration/Assets/discardIcon.svg";
import editIconWhite from "../../../assets/icons/Panel/administration/AssetGroup/editIconWhite.svg";

const bodyData = [
  {
    id: 0,
    data: [
      {
        id: 0,
        row: "AA 091 OH",
      },
      {
        id: 1,
        row: "Makina/Car",
      },
      {
        id: 2,
        row: "FMB130",
      },
      {
        id: 3,
        row: 359633108103750,
      },
      {
        id: 4,
        row: "ardian.starja@vodafone.com--2",
      },
      {
        id: 5,
        row: "29/07/2024 17:59",
      },
    ],
  },
];

const Assets = () => {
  const [view, setView] = useState("table");
  const [formData, setFormData] = useState({
    assetName: "",
    serialNumber: "",
    assetStatus: "",
    assetGroup: "default",
    assetType: "",
  });

  // eslint-disable-next-line no-unused-vars
  const [rowData, setRowData] = useState(null);

  // console.log("This is rowData:", rowData);

  const onSubmit = () => {
    console.log("this is formData:", formData);
  };

  return (
    <div className="pageLayout">
      <div className="w-full flex flex-col gap-2 lg:gap-0 lg:flex-row lg:justify-between lg:items-center bg-mainBg pt-5 z-10">
        <PageTitle
          titleLngId={
            view === "create"
              ? "assets.createAssetTitle"
              : view === "view"
              ? "assets.assetDetails"
              : view === "edit"
              ? "assets.assetDetailsEdit"
              : "assets.pageTitle"
            // titleLngId
          }
          subTitleLngId={"app.administrationPanel"}
          subTitle2LngId={"assets.subtitle2"}
          state={view}
          setState={() => setView("table")}
        ></PageTitle>

        <div className=" flex items-center gap-2 mb-4 lg:mb-0">
          {view === "create" && (
            <ReusableBtn
              icon={discardIcon}
              mainStyles={
                "w-[100px] h-[45px] px-1 gap-2 bg-transparent border border-[#0D0D0D] rounded-md"
              }
              textStyles={"text-filterBtn text-[#0D0D0D]"}
              text={"assets.createAssetBtn1"}
              handleClick={() => setView("table")}
            />
          )}
          <ReusableBtn
            icon={
              view === "create"
                ? ""
                : view === "view"
                ? editIconWhite
                : plusIcon
            }
            mainStyles={"w-fit h-[45px] px-4 gap-2 bg-redPrimary rounded-md"}
            textStyles={"text-filterBtn text-[#FFFFFF]"}
            text={
              view === "create" || view === "table"
                ? "assets.btn1"
                : "assetGroup.assetGroupDetailsBtn1"
            }
            handleClick={
              view === "create"
                ? onSubmit
                : view === "view"
                ? () => console.log("edit page")
                : () => setView("create")
            }
          />

          {view === "table" && (
            <>
              <ReusableBtn
                icon={fileIcon}
                mainStyles={"h-[45px] px-3 bg-white rounded-md"}
                handleClick={() => console.log("")}
              />

              <ReusableBtn
                icon={funnelIcon}
                text={"(81)"}
                mainStyles={"h-[45px] px-3 bg-white rounded-md"}
                handleClick={() => console.log("")}
              />
            </>
          )}
        </div>
      </div>

      {view === "create" && (
        <CreateAsset
          formData={formData}
          setFormData={setFormData}
        ></CreateAsset>
      )}

      {view === "view" && (
        <ViewAsset
          titleLngId={"assets.assetDetailsText"}
          titles={AssetDetailTitles}
          data={rowData}
        ></ViewAsset>
      )}

      {view === "edit" && <div>Edit</div>}

      {view === "table" && (
        <Table
          version={1}
          headerData={AssetsTable}
          bodyData={bodyData}
          setState={setView}
          setRowData={setRowData}
        />
      )}
    </div>
  );
};

export default Assets;
