/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { FormattedMessage } from "react-intl";
import SelectInput from "../../../../components/Inputs/SelectInput";

import closeIcon from "../../../../assets/icons/Panel/Logout/close-icon.svg";
import { uploadTemplateOptions } from "../../../../constants";
import Upload from "./Upload";
import ReusableBtn from "../../../../components/ReusableBtn/ReusableBtn";

const AddTemplate = ({ handleBtnActions }) => {
  const [formData, setFormData] = useState({
    templateType: "",
    excel: null,
  });

  const [isUploading, setIsUploading] = useState(false);

  const handleChange = (event) => {
    const { id } = event.target;

    if (id === "excel") {
      setIsUploading(true);

      setFormData((prev) => ({ ...prev, [id]: event.target.files[0] }));
    } else {
      setFormData((prev) => ({ ...prev, [id]: event.target.value }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    console.log("This is submit");
  };

  useEffect(() => {
    if (formData.excel) {
      setIsUploading(false);
    }
  }, [formData.excel]);

  return (
    <div className="createEventModal">
      <form
        className="bg-white flex flex-col justify-between rounded-lg shadow-2xl w-full max-w-[980px] h-full max-h-[350px] py-5 px-6 overflow-y-scroll mx-5"
        onSubmit={handleSubmit}
      >
        <header className="flex justify-between items-center pb-4 border-b-2 border-gray-100 gap-5">
          <div className="flex flex-1 items-center justify-center gap-5">
            <h2 className=" text-text18 font-bold">
              <FormattedMessage id="bulkUploads.addTemplateTitle" />
            </h2>
            <SelectInput
              id={"templateType"}
              name={"templateType"}
              className={
                "w-full max-w-[280px] border rounded-md h-10 flex py-1 px-2  text-extraSmall text-textSecondary"
              }
              options={uploadTemplateOptions}
              onChange={handleChange}
            />
          </div>
          <img
            id="template"
            src={closeIcon}
            onClick={handleBtnActions}
            className=" cursor-pointer size-4 active:opacity-75 hover:opacity-85"
          />
        </header>

        <Upload
          mainStyles={"h-[120px]"}
          handleChange={handleChange}
          isUploading={isUploading}
          fileName={formData.excel && formData.excel.name}
        />

        <div className="flex justify-end">
          <ReusableBtn
            id={"add"}
            type={"submit"}
            mainStyles={"w-[116px] h-[40px] bg-redPrimary rounded-md"}
            textStyles={"text-regularSize !text-white"}
            text={"bulkUploads.add"}
          />
        </div>
      </form>
    </div>
  );
};

AddTemplate.propTypes = {
  handleBtnActions: PropTypes.func,
};

export default AddTemplate;
