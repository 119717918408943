/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */

import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { PropTypes } from "prop-types";

const OverviewComponent = ({ lngId, selectedValue, isArray }) => {
  let objectKeyNames = [];

  const buildKeyNamesArray = (obj) => {
    for (const key in obj) {
      if (obj[key] === true) {
        objectKeyNames.push(key);
      }
    }
  };

  if (selectedValue && typeof selectedValue === "object") {
    if (isArray) {
      objectKeyNames = [...selectedValue];
    } else {
      buildKeyNamesArray(selectedValue);
    }
  }

  return (
    <div className="">
      <label className=" text-extraSmall text-darkGray">
        <FormattedMessage id={lngId} />
      </label>
      <div className=" text-text16 text-pageTitle font-bold">
        {selectedValue && typeof selectedValue === "object"
          ? objectKeyNames.map((item, index) => {
              if (index === objectKeyNames.length - 1) {
                return <span key={item}>{item}</span>;
              } else {
                return <span key={item}>{item}, </span>;
              }
            })
          : selectedValue
          ? selectedValue
          : "-"}
      </div>
    </div>
  );
};

OverviewComponent.propTypes = {
  lngId: PropTypes.string,
  selectedValue: PropTypes.string,
  isArray: PropTypes.boolean,
};

export default OverviewComponent;
