// eslint-disable-next-line no-unused-vars
import React from "react";
import { PropTypes } from "prop-types";
import { FormattedMessage } from "react-intl";

const Toggle = ({
  title,
  titleColor,
  titleSize,
  background,
  borderColor,
  label,
  labelColor,
  labelSize,
  toggleId,
  uppercase,
  toggle,
  setToggle,
  name,
  setSelectedRows,
  isChecked,
  bodyData,
}) => {
  const handleClick = () => {
    setToggle((prev) => !prev);

    if (isChecked?.all === false) {
      setSelectedRows([...bodyData]);
    } else {
      setSelectedRows([]);
    }
  };

  return (
    <div className={`${uppercase && "uppercase"} space-y-1`}>
      {title && (
        <span
          className=" text-extraSmall"
          style={{ color: titleColor, fontSize: titleSize }}
        >
          <FormattedMessage id={title} />
        </span>
      )}
      <div className="flex items-center gap-3">
        <div
          id={toggleId}
          name={name}
          className="w-7 h-[10px] rounded-lg flex justify-start items-center cursor-pointer"
          style={{
            background: toggle ? "rgba(40, 67, 135,0.85)" : background,
            justifyContent: toggle ? "flex-end" : "flex-start",
          }}
          // onClick={() => setToggle((prev) => !prev)}
          onClick={handleClick}
        >
          <div
            className=" w-4 h-4 border border-[#9AA8B3] rounded-full bg-white"
            style={{ borderColor }}
          ></div>
        </div>
        <label
          htmlFor={toggleId}
          style={{ color: labelColor, fontSize: labelSize }}
        >
          <FormattedMessage id={label} />
        </label>
      </div>
    </div>
  );
};

Toggle.propTypes = {
  title: PropTypes.string,
  titleColor: PropTypes.string,
  titleSize: PropTypes.string,
  background: PropTypes.string,
  borderColor: PropTypes.string,
  label: PropTypes.string,
  labelColor: PropTypes.string,
  labelSize: PropTypes.string,
  toggleId: PropTypes.string,
  uppercase: PropTypes.bool,
  toggle: PropTypes.bool,
  setToggle: PropTypes.func,
  name: PropTypes.string,
  setSelectedRows: PropTypes.func,
  isChecked: PropTypes.Object,
  bodyData: PropTypes.Object,
};

export default Toggle;
