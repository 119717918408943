import React from "react";
import { PropTypes } from "prop-types";
import { FormattedMessage } from "react-intl";

const CircleCheckbox = ({
  handleChange,
  id,
  value,
  valueToCheck,
  lngId,
  mainStyles,
  containerStyles,
  checkedStyles,
}) => {
  return (
    <div
      className={`flex items-center gap-2 w-fit ${mainStyles}`}
      onClick={handleChange}
      id={id}
    >
      <div
        className={`customRadioContainer ${containerStyles}`}
        style={{
          borderColor: value === valueToCheck ? "#007C92" : "#ACACAC",
        }}
      >
        {value === valueToCheck && <div className={`${checkedStyles}`}></div>}
      </div>
      {lngId && (
        <div className=" text-text16 text-blackPrimary pointer-events-none">
          <label
            htmlFor={id}
            className={
              "text-extraSmall text-textSecondary flex items-center gap-2"
            }
          ></label>
          <span>
            <FormattedMessage id={lngId} />
          </span>
        </div>
      )}
    </div>
  );
};

CircleCheckbox.propTypes = {
  handleChange: PropTypes.func,
  id: PropTypes.string,
  value: PropTypes.boolean,
  lngId: PropTypes.string,
  checkedStyles: PropTypes.string,
  containerStyles: PropTypes.string,
  mainStyles: PropTypes.string,
  valueToCheck: PropTypes.string ?? PropTypes.boolean,
};

export default CircleCheckbox;
