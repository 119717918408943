/* eslint-disable indent */
/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */
import React, { useState } from "react";
import PageTitle from "../../../components/Layout/PageTitle";
import Table from "../Components/table/Table";
import ViewPoisGroup from "./components/ViewPoisGroup";
import { PoiGroupTable } from "../../../constants";

import plusIcon from "../../../assets/icons/Panel/administration/Assets/plus-icon.svg";
import fileIcon from "../../../assets/icons/Panel/administration/Assets/file-icon.svg";
import funnelIcon from "../../../assets/icons/Panel/administration/Assets/funnel-icon.svg";
import discardIcon from "../../../assets/icons/Panel/administration/Assets/discardIcon.svg";
import editIconWhite from "../../../assets/icons/Panel/administration/AssetGroup/editIconWhite.svg";
import ReusableBtn from "../../../components/ReusableBtn/ReusableBtn";

const bodyData = [
  {
    id: 0,
    data: [
      {
        id: 0,
        row: "Durres",
      },
      {
        id: 1,
        row: "DR-Kabinete",
      },
      {
        id: 2,
        row: "Vendndodhjet",
      },
      {
        id: 3,
        row: "13/01/23 13:02",
      },
      {
        id: 4,
        row: "jurgen.hoxholli@adc.al",
      },
    ],
  },
];

const PoisGroups = () => {
  const [view, setView] = useState("table");

  // const [formData, setFormData] = useState({
  //   assetName: "",
  //   serialNumber: "",
  //   assetStatus: "",
  //   assetGroup: "default",
  //   assetType: "",
  // });

  // eslint-disable-next-line no-unused-vars
  const [rowData, setRowData] = useState(null);

  // console.log("This is rowData:", rowData);

  const onSubmit = () => {
    // console.log("this is formData:", formData);
  };

  return (
    <div className="pageLayout">
      <div className="w-full flex flex-col gap-2 lg:gap-0 lg:flex-row lg:justify-between lg:items-center bg-mainBg pt-5 z-10">
        <PageTitle
          titleLngId={
            view === "create" ? "poisGroup.createNewPoi" : "poisGroup.pageTitle"
          }
          subTitleLngId={"app.administrationPanel"}
          subTitle2LngId={"poisGroup.subtitle2"}
          state={view}
          setState={() => setView("table")}
        ></PageTitle>

        <div className=" flex items-center gap-2 mb-4 lg:mb-0">
          {view === "create" && (
            <ReusableBtn
              text={"assets.createAssetBtn1"}
              icon={discardIcon}
              mainStyles={
                "w-[100px] h-[45px] bg-transparent gap-2 px-1 border border-[#0D0D0D] rounded-md"
              }
              textStyles={"text-filterBtn text-[#0D0D0D]"}
              handleClick={() => setView("table")}
            />
          )}

          <ReusableBtn
            imgWidth={"14px"}
            text={
              view === "create" || view === "table"
                ? "poisGroup.btn1"
                : "assetGroup.assetGroupDetailsBtn1"
            }
            icon={
              view === "create"
                ? ""
                : view === "view"
                ? editIconWhite
                : plusIcon
            }
            mainStyles={"w-fit h-[45px] bg-redPrimary gap-2 px-4 rounded-md"}
            textStyles={"text-filterBtn text-[#FFFFFF]"}
            handleClick={
              view === "create"
                ? onSubmit
                : view === "view"
                ? () => console.log("edit page")
                : () => setView("create")
            }
          />

          {view === "table" && (
            <>
              <ReusableBtn
                icon={fileIcon}
                mainStyles={"h-[45px] bg-white px-3 rounded-md"}
                handleClick={() => console.log("")}
              />

              <ReusableBtn
                icon={funnelIcon}
                text={"(81)"}
                textStyles={"text-textSecondary"}
                mainStyles={"h-[45px] bg-white px-3 gap-1 rounded-md"}
                handleClick={() => console.log("")}
              />
            </>
          )}
        </div>
      </div>

      {/* {view === "create" && (
        <CreateAsset
          formData={formData}
          setFormData={setFormData}
        ></CreateAsset>
      )}
 */}

      {view === "view" && <ViewPoisGroup data={rowData} />}

      {view === "edit" && <div>Edit</div>}

      {view === "table" && (
        <Table
          version={1}
          headerData={PoiGroupTable}
          bodyData={bodyData}
          setState={setView}
          setRowData={setRowData}
        />
      )}
    </div>
  );
};

export default PoisGroups;
