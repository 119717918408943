import React from "react";
import { PropTypes } from "prop-types";
import { FormattedMessage } from "react-intl";

// import dateIcon from "../../assets/icons/Panel/Raports/dateIcon.svg";

const DateInput = ({
  inputId,
  labelLngId,
  value,
  parentClassName,
  className,
  handleChange,
}) => {
  return (
    <div className={`${parentClassName} flex flex-col`}>
      {labelLngId && (
        <label htmlFor={inputId} className=" text-extraSmall text-blackPrimary">
          <FormattedMessage id={labelLngId} />
        </label>
      )}
      <div className={`${className}`}>
        {/* <img
          src={dateIcon}
          className=" absolute top-[50%] translate-y-[-50%] right-4 cursor-pointer pointer-events-none"
        ></img> */}
        <input
          id={inputId}
          type="date"
          className="w-full h-full bg-transparent outline-none"
          value={value}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

DateInput.propTypes = {
  inputId: PropTypes.string,
  labelLngId: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  parentClassName: PropTypes.string,
  handleChange: PropTypes.func,
};

export default DateInput;
