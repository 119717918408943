import React, { useEffect } from "react";
import { PropTypes } from "prop-types";
import { FormattedMessage } from "react-intl";

import InputWithLabel from "../../../../components/Inputs/InputWithLabel";
import SelectInput from "../../../../components/Inputs/SelectInput";
import DrawingInstructions from "./DrawingInstructions";
import {
  poisOptions1,
  poisOptions2,
  ViewPoisTable,
} from "../../../../constants";
import CircleCheckbox from "../../../../components/Inputs/CircleCheckbox";
import Table from "../../Components/table/Table";

const CreatePoi = ({
  formData,
  setFormData,
  radius,
  setRadius,
  handleChange,
  view,
}) => {
  useEffect(() => {
    return () => {
      setFormData({
        name: "",
        assignToGroup: "",
        setType: "",
        registeredAddress: "",
        coordinates: "",
        radius: true,
        // meters: 2300,
        drawAShape: false,
      });

      setRadius(2300);
    };
  }, []);

  return (
    <div className="py-5 space-y-4">
      <InputWithLabel
        inputId={"name"}
        name={"name"}
        labelLngId={"pois.textInputLabel1"}
        mainStyles={`${view === "create" && "border rounded-md"}`}
        labelStyles={"text-extraSmall text-textSecondary"}
        handleChange={handleChange}
        value={formData.name}
      />

      {view === "view" && (
        <>
          <InputWithLabel
            inputId={"setType"}
            name={"setType"}
            labelLngId={"pois.textInputLabel3"}
            mainStyles={`${view === "create" && "border rounded-md"}`}
            labelStyles={"text-extraSmall text-textSecondary"}
            handleChange={handleChange}
            value={formData.setType}
          />

          <InputWithLabel
            inputId={"country"}
            name={"country"}
            labelLngId={"pois.textInputLabel6"}
            mainStyles={`${view === "create" && "border rounded-md"}`}
            labelStyles={"text-extraSmall text-textSecondary"}
            handleChange={handleChange}
            value={formData.country}
          />
        </>
      )}

      {view === "create" && (
        <>
          <SelectInput
            id={"assignToGroup"}
            name={"assignToGroup"}
            className={
              "w-full h-[50px] outline-none border rounded-md px-2 text-gray-300"
            }
            options={poisOptions1}
            labelLngId={"pois.textInputLabel2"}
            labelStyles={"text-extraSmall text-textSecondary ml-1"}
            onChange={handleChange}
          />
          <SelectInput
            id={"setType"}
            name={"setType"}
            className={
              "w-full h-[50px] outline-none border rounded-md px-2 text-gray-300"
            }
            options={poisOptions2}
            labelLngId={"pois.textInputLabel3"}
            labelStyles={"text-extraSmall text-textSecondary ml-1"}
            onChange={handleChange}
          />
          <DrawingInstructions />
        </>
      )}

      <InputWithLabel
        inputId={"registeredAddress"}
        name={"registeredAddress"}
        labelLngId={"pois.textInputLabel4"}
        mainStyles={`${view === "create" && "border rounded-md"}`}
        labelStyles={"text-extraSmall text-textSecondary"}
        handleChange={handleChange}
        value={formData.registeredAddress}
        placeholderLngId={"pois.textInput4placeholder"}
      />

      <InputWithLabel
        inputId={"coordinates"}
        name={"coordinates"}
        labelLngId={"pois.textInputLabel5"}
        mainStyles={`${view === "create" && "border rounded-md"}`}
        labelStyles={"text-extraSmall text-textSecondary"}
        // handleChange={handleChange}
        value={formData.coordinates}
      />

      {view === "create" && (
        <div className="p-3 space-y-3">
          <label className="text-extraSmall text-textSecondary">
            <FormattedMessage id="pois.bordersSectionTitle" />
          </label>

          <div className="flex items-center gap-4 cursor-pointer">
            <CircleCheckbox
              handleChange={handleChange}
              id={"radius"}
              value={formData.radius}
              lngId={"pois.radius"}
              containerStyles={"!size-5"}
              checkedStyles={"!size-4"}
              valueToCheck={true}
            />
            <input
              id="meters"
              type="number"
              className=" outline-none w-16 border rounded-md pl-2 text-extraSmall"
              value={radius}
              onChange={(event) => setRadius(Number(event.target.value))}
            ></input>
            <span className=" text-extraSmall -ml-2">
              <FormattedMessage id="pois.meters" />
            </span>
          </div>

          <CircleCheckbox
            handleChange={handleChange}
            id={"drawAShape"}
            value={formData.drawAShape}
            lngId={"pois.shape"}
            containerStyles={"!size-5"}
            checkedStyles={"!size-4"}
            valueToCheck={true}
          />
        </div>
      )}

      {view === "view" && (
        <Table
          headerData={ViewPoisTable}
          // bodyData={filteredUserArray}
          // setState
          // setRowData
          // version={4}
          // setSelectedRows={setSelectedRows}
          // selectedRows={selectedRows}
          // isChecked={isChecked}
          // setIsChecked={setIsChecked}
          shadow={true}
        />
      )}
    </div>
  );
};

CreatePoi.propTypes = {
  formData: PropTypes.object,
  handleChange: PropTypes.func,
  setFormData: PropTypes.func,
  radius: PropTypes.number,
  setRadius: PropTypes.func,
  view: PropTypes.string,
};

export default CreatePoi;
