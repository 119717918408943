/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import PageTitle from "../../../components/Layout/PageTitle";
import ReusableBtn from "../../../components/ReusableBtn/ReusableBtn";

import plusIcon from "../../../assets/icons/Panel/administration/Assets/plus-icon.svg";
import fileIcon from "../../../assets/icons/Panel/administration/Assets/file-icon.svg";
import funnelIcon from "../../../assets/icons/Panel/administration/Assets/funnel-icon.svg";
import discardIcon from "../../../assets/icons/Panel/administration/Assets/discardIcon.svg";
import editIconWhite from "../../../assets/icons/Panel/administration/AssetGroup/editIconWhite.svg";
import Table from "../Components/table/Table";
import NewTimetable from "./components/NewTimetable";
import { TimetablesTable } from "../../../constants";

const bodyData = [
  {
    id: 0,
    data: [
      {
        id: 0,
        row: "B2B + BIR",
      },
      {
        id: 1,
        row: "08-18:30",
      },
      {
        id: 2,
        row: "24/01/23 16:27",
      },
      {
        id: 3,
        row: "hurgen.hoxholli@adc.al",
      },
      {
        id: 4,
        row: "24/01/23 16:33",
      },
      {
        id: 5,
        row: "jurgen.hoxholli@adc.al",
      },
    ],
  },
];

const Timetable = () => {
  const [view, setView] = useState("table");

  // eslint-disable-next-line no-unused-vars
  const [rowData, setRowData] = useState(null);

  const onSubmit = () => {
    // console.log("this is formData:", formData);
  };

  const handleBtnActions = (event) => {
    const { id } = event.target;

    setView(id);
  };

  return (
    <div className="pageLayout">
      <div className="w-full flex flex-col gap-2 lg:gap-0 lg:flex-row lg:justify-between lg:items-center bg-mainBg pt-5 z-10">
        <PageTitle
          titleLngId={
            view === "create" ? "timetables.btn1" : "timetables.subtitle2"
          }
          subTitleLngId={"app.administrationPanel"}
          subTitle2LngId={"timetables.subtitle2"}
          state={view}
          setState={() => setView("table")}
        ></PageTitle>

        <div className=" flex items-center gap-2 mb-4 lg:mb-0">
          {view === "table" && (
            <>
              <ReusableBtn
                id={"create"}
                icon={plusIcon}
                mainStyles={
                  "w-fit h-[45px] px-4 gap-2 bg-redPrimary rounded-md"
                }
                textStyles={"text-filterBtn text-[#FFFFFF]"}
                text={"timetables.btn1"}
                handleClick={handleBtnActions}
              />

              <ReusableBtn
                icon={funnelIcon}
                text={"(81)"}
                mainStyles={"h-[45px] px-3 bg-white rounded-md"}
                handleClick={() => console.log("")}
              />
            </>
          )}
        </div>
      </div>

      {view === "create" && (
        <NewTimetable handleBtnActions={handleBtnActions} />
      )}

      {view === "table" && (
        <Table
          version={6}
          headerData={TimetablesTable}
          bodyData={bodyData}
          setState={setView}
          setRowData={setRowData}
        />
      )}
    </div>
  );
};

export default Timetable;
