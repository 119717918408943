/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { GenerateRaportTable } from "../../../../constants";
import { FormattedMessage } from "react-intl";
import RaportGroup from "../../../Raports/Components/RaportGroup";
import SearchInput from "../../../../components/Inputs/SearchInput";
import CustomRadioBtn from "../../../../components/RadioBtn/CustomRadioBtn";
import AssetGroupToggle from "../../../../components/Inputs/AssetGroupToggle";
import TextInput from "../../../../components/Inputs/TextInput";
import SelectedUserField from "../../../Raports/Components/SelectedUserField";
import Table from "../../Components/table/Table";
import SimpleToggle from "../../../../components/Inputs/SimpleToggle";
import OverviewComponent from "./OverviewComponent";
import leftArrowIcon from "../../../../assets/icons/Panel/Raports/leftArrowIcon.svg";
import rightArrowIcon from "../../../../assets/icons/Panel/Raports/rightArrowIcon.svg";
import searchIcon from "../../../../assets/icons/Panel/searchIcon.svg";
import crownIcon from "../../../../assets/icons/Panel/administration/Notifications/crownIcon.svg";
import AlertType from "./AlertType";
import ReusableBtn from "../../../../components/ReusableBtn/ReusableBtn";

const bodyData = [
  {
    id: 0,
    data: [
      {
        id: 0,
        row: "Ada Nakuci",
      },
      {
        id: 1,
        row: "ada.nakuci@adc.al",
      },
    ],
  },
  {
    id: 1,
    data: [
      {
        id: 0,
        row: "Daku Lu",
      },
      {
        id: 1,
        row: "daku.lu@adc.al",
      },
    ],
  },
];

const CreateNewNotification = ({ formData, setFormData, handleChange }) => {
  const [raportGroupIndex, setRaportGroupIndex] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isChecked, setIsChecked] = useState({
    all: false,
  });
  const [searchValue, setSearchValue] = useState("");

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleNextBtnClick = useCallback(() => {
    setRaportGroupIndex((prev) => prev + 1);
  }, []);

  const handleBackBtnClick = useCallback(() => {
    setRaportGroupIndex((prev) => prev - 1);
  }, []);

  const filteredUserArray = bodyData.filter((el) =>
    el.data[0].row.toLowerCase().includes(searchValue.toLowerCase())
  );

  useEffect(() => {
    setFormData((prevValues) => ({
      ...prevValues,
      users: {
        ...prevValues.users,
        selectedUsers: selectedRows,
      },
    }));
  }, [selectedRows, setFormData]);

  useEffect(() => {
    return () =>
      setFormData({
        name: "",
        description: "",
        assets: {
          applyTo: "",
          assetGroups: [],
        },
        alertTypes: { type: "", alert: [] },
        users: {
          applyTo: "",
          selectedUsers: [],
        },
        methodsAndMessage: {
          PopUp: true,
          Browser: true,
          SMS: false,
          Email: false,
          Push: false,
        },
      });
  }, []);

  return (
    <form id="createAssetForm" className="formContainer">
      <RaportGroup
        raportGroupIndex={raportGroupIndex}
        index={0}
        contentTitleLngId={"notifications.group1Title"}
        readyToGo={formData.name && formData.description}
      >
        <div className=" space-y-5">
          <SearchInput
            lngId={"notification.group1Placeholder1"}
            id={"name"}
            labelLngId={"notification.group1Label1"}
            labelStyle={" text-regularSize text-blackPrimary"}
            searchValue={formData.name}
            handleChange={handleChange}
            containerStyles={"border-b-[1px] border-[#EAF1F4] w-full xl:w-1/2"}
            mainStyles={"text-regularSize text-[#606A70] h-[30px]"}
          />

          <SearchInput
            lngId={"notification.group1Placeholder2"}
            id={"description"}
            labelLngId={"notification.group1Label2"}
            labelStyle={"text-regularSize text-blackPrimary "}
            searchValue={formData.description}
            handleChange={handleChange}
            containerStyles={"border-b-[1px] border-[#EAF1F4] w-full xl:w-1/2"}
            mainStyles={"text-regularSize text-[#606A70] h-[30px]"}
          />
        </div>

        <div className="next-btn">
          <ReusableBtn
            icon={rightArrowIcon}
            text={"raports.nextBtn"}
            mainStyles={
              "w-[130px] h-[45px] bg-redPrimary gap-2 px-3 rounded-[30px]"
            }
            textStyles={"text-text16 text-[#F8F8F8]"}
            version={2}
            handleClick={handleNextBtnClick}
          />
        </div>
      </RaportGroup>

      <RaportGroup
        raportGroupIndex={raportGroupIndex}
        index={1}
        contentTitleLngId={"notifications.group2Title"}
        readyToGo={formData.assets.applyTo}
      >
        <div className="space-y-4">
          <div className=" flex items-center gap-4 text-extraSmall">
            <span className=" uppercase text-darkGray">
              <FormattedMessage id="notification.group2Text1" />
            </span>
            <span
              id="All assets"
              className="flex gap-2 items-center  text-blackPrimary cursor-pointer "
              onClick={handleChange}
            >
              <CustomRadioBtn
                data={formData.assets.applyTo}
                value={"All assets"}
              ></CustomRadioBtn>
              <FormattedMessage id="notification.group2Text2" />
            </span>
            <span
              id="Specific"
              className="flex gap-2 items-center  text-blackPrimary cursor-pointer"
              onClick={handleChange}
            >
              <CustomRadioBtn
                data={formData.assets.applyTo}
                value={"Specific"}
              ></CustomRadioBtn>
              <FormattedMessage id="notification.group2Text3" />
            </span>
          </div>

          <div className="flex gap-[6px] text-xxs text-blackPrimary">
            <span>
              <FormattedMessage id="notification.group2Text4.1" />
            </span>
            <span className=" font-bold">
              <FormattedMessage id="notification.group2Text4.2" />
            </span>
            <span>
              <FormattedMessage id="notification.group2Text4.3" />
            </span>
          </div>

          {formData.assets.applyTo === "Specific" && (
            <div>
              <AssetGroupToggle
                label={"Default"}
                icon={crownIcon}
                setFormData={setFormData}
              />
              <AssetGroupToggle label={"ADC"} setFormData={setFormData} />
              <AssetGroupToggle
                label={"AR-NETWORK - Shkoder"}
                setFormData={setFormData}
              />
              <AssetGroupToggle label={"Hellas"} setFormData={setFormData} />
            </div>
          )}
        </div>

        <div className="next-btn">
          <ReusableBtn
            icon={leftArrowIcon}
            text={"raports.backBtn"}
            mainStyles={
              "w-[130px] h-[45px] gap-2 border border-[#999999] rounded-[30px]"
            }
            textStyles={"text-text16 text-[#999999]"}
            handleClick={handleBackBtnClick}
          />

          <ReusableBtn
            icon={rightArrowIcon}
            text={"raports.nextBtn"}
            mainStyles={"w-[130px] h-[45px] bg-redPrimary gap-2 rounded-[30px]"}
            version={2}
            textStyles={"text-text16 text-[#F8F8F8]"}
            handleClick={handleNextBtnClick}
          />
        </div>
      </RaportGroup>

      <RaportGroup
        raportGroupIndex={raportGroupIndex}
        index={2}
        contentTitleLngId={"notifications.group3Title"}
        readyToGo={formData.alertTypes.type}
      >
        <div className=" space-y-4">
          <div className=" flex items-center gap-4 text-extraSmall">
            <span className=" uppercase text-darkGray">
              <FormattedMessage id="notification.group2Text1" />
            </span>

            <span
              id="All Alert Types"
              className="flex gap-2 items-center  text-blackPrimary cursor-pointer "
              // onClick={(event) => handleChange(event)}
              onClick={handleChange}
            >
              <CustomRadioBtn
                data={formData.alertTypes.type}
                value={"All Alert Types"}
              ></CustomRadioBtn>
              <FormattedMessage id="notification.group3Text2" />
            </span>
            <span
              id="Specific Alert Types"
              className="flex gap-2 items-center  text-blackPrimary cursor-pointer"
              // onClick={(event) => handleChange(event)}
              onClick={handleChange}
            >
              <CustomRadioBtn
                data={formData.alertTypes.type}
                value={"Specific Alert Types"}
              ></CustomRadioBtn>
              <FormattedMessage id="notification.group3Text3" />
            </span>
          </div>

          <div className="flex gap-[6px] text-xxs text-blackPrimary">
            <span>
              <FormattedMessage id="notification.group3Text4" />
            </span>
            <span className=" font-bold">
              <FormattedMessage
                id={
                  formData.alertTypes.type === "All Alert Types"
                    ? "notification.group3Text4.2"
                    : "notification.group3Text4.2.1"
                }
              />
            </span>
            <span>
              <FormattedMessage id="notification.group3Text4.3" />
            </span>
          </div>

          {formData.alertTypes.type === "Specific Alert Types" && (
            <div>
              <AlertType setFormData={setFormData} label={"DWELL TIME"} />
            </div>
          )}
        </div>

        <div className="next-btn">
          <ReusableBtn
            icon={leftArrowIcon}
            text={"raports.backBtn"}
            mainStyles={
              "w-[130px] h-[45px] gap-2 border border-[#999999] rounded-[30px]"
            }
            textStyles={"text-text16 text-[#999999]"}
            handleClick={handleBackBtnClick}
          />

          <ReusableBtn
            icon={rightArrowIcon}
            text={"raports.nextBtn"}
            mainStyles={"w-[130px] h-[45px] bg-redPrimary gap-2 rounded-[30px]"}
            textStyles={"text-text16 text-[#F8F8F8]"}
            handleClick={handleNextBtnClick}
          />
        </div>
      </RaportGroup>

      <RaportGroup
        raportGroupIndex={raportGroupIndex}
        index={3}
        contentTitleLngId={"notifications.group4Title"}
        readyToGo={formData.users.applyTo}
      >
        <div className=" space-y-6">
          <div className=" flex flex-col justify-start sm:flex-row items-center gap-4 text-extraSmall ">
            <div className=" uppercase text-darkGray">
              <FormattedMessage id="notification.group2Text1" />
            </div>

            <div
              id="All Users"
              className="flex w-full sm:w-fit gap-2 items-center  text-blackPrimary cursor-pointer "
              onClick={handleChange}
            >
              <CustomRadioBtn
                data={formData.users.applyTo}
                value={"All Users"}
              ></CustomRadioBtn>
              <FormattedMessage id="notification.group4Text2" />
            </div>
            <div
              id="Multiple Users"
              className="flex w-full sm:w-fit gap-2 items-center  text-blackPrimary cursor-pointer"
              onClick={handleChange}
            >
              <CustomRadioBtn
                data={formData.users.applyTo}
                value={"Multiple Users"}
              ></CustomRadioBtn>
              <FormattedMessage id="notification.group4Text3" />
            </div>
            <div
              id="User Groups"
              className="flex w-full sm:w-fit gap-2 items-center  text-blackPrimary cursor-pointer"
              onClick={handleChange}
            >
              <CustomRadioBtn
                data={formData.users.applyTo}
                value={"User Groups"}
              ></CustomRadioBtn>
              <FormattedMessage id="notification.group4Text4" />
            </div>
          </div>

          <div className="flex gap-[6px] text-xxs text-blackPrimary">
            <span>
              <FormattedMessage id="notification.group4Text5.1" />
            </span>
            <span className=" font-bold">
              <FormattedMessage
                id={
                  formData.users.applyTo === "All Users"
                    ? "notification.group4Text5.2"
                    : formData.users.applyTo === "Multiple Users"
                    ? "notification.group4Text5.2.1"
                    : "notification.group4Text5.2.2"
                }
              />
            </span>
            <span>
              <FormattedMessage id="notification.group4Text5.3" />
            </span>
          </div>

          {formData.users.applyTo !== "All Users" &&
            formData.users.applyTo !== "" && (
              <div>
                <TextInput
                  inputClassName={
                    "flex w-full h-[50px] pl-4 text-regularSize border border-[#EBEBEB] rounded-md"
                  }
                  placeholderLngId={"raport.group2SearchPlaceholder"}
                  id={"searchInput2"}
                  formData={searchValue}
                  handleChange={handleSearchChange}
                  icon={searchIcon}
                />

                {selectedRows.length === 1 && (
                  <SelectedUserField
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    setIsChecked={setIsChecked}
                  ></SelectedUserField>
                )}

                <Table
                  headerData={GenerateRaportTable}
                  bodyData={filteredUserArray}
                  setState
                  setRowData
                  version={4}
                  setSelectedRows={setSelectedRows}
                  selectedRows={selectedRows}
                  isChecked={isChecked}
                  setIsChecked={setIsChecked}
                  shadow={true}
                />
              </div>
            )}
        </div>

        <div className="next-btn">
          <ReusableBtn
            icon={leftArrowIcon}
            text={"raports.backBtn"}
            mainStyles={
              "w-[130px] h-[45px] gap-2 border border-[#999999] rounded-[30px]"
            }
            textStyles={"text-text16 text-[#999999]"}
            handleClick={handleBackBtnClick}
          />

          <ReusableBtn
            icon={rightArrowIcon}
            text={"raports.nextBtn"}
            mainStyles={"w-[130px] h-[45px] bg-redPrimary gap-2 rounded-[30px]"}
            textStyles={"text-text16 text-[#F8F8F8]"}
            handleClick={handleNextBtnClick}
          />
        </div>
      </RaportGroup>

      <RaportGroup
        raportGroupIndex={raportGroupIndex}
        index={4}
        contentTitleLngId={"notifications.group5Title"}
        readyToGo={true}
      >
        <div>
          <div>
            <div className=" uppercase text-regularSize">
              <FormattedMessage id="notification.group2Text1" />
            </div>
            <div className="text-xxs text-darkGray">
              <FormattedMessage id="notification.group5Text2" />
            </div>
          </div>

          <div className="flex flex-col lg:flex-row lg:gap-9 py-5 border-b border-[#EBEBEB]">
            <SimpleToggle
              formData={formData}
              handleChange={handleChange}
              id={"PopUp"}
              label={<FormattedMessage id="notification.group5Text3" />}
            />
            <SimpleToggle
              formData={formData}
              handleChange={handleChange}
              id={"Browser"}
              label={<FormattedMessage id="notification.group5Text4" />}
            />
            <SimpleToggle
              formData={formData}
              handleChange={handleChange}
              id={"SMS"}
              label={<FormattedMessage id="notification.group5Text5" />}
            />
            <SimpleToggle
              formData={formData}
              handleChange={handleChange}
              id={"Email"}
              label={<FormattedMessage id="notification.group5Text6" />}
            />
            <SimpleToggle
              formData={formData}
              handleChange={handleChange}
              id={"Push"}
              label={<FormattedMessage id="notification.group5Text7" />}
            />
          </div>
        </div>

        <div className="next-btn">
          <ReusableBtn
            icon={leftArrowIcon}
            text={"raports.backBtn"}
            mainStyles={
              "w-[130px] h-[45px] gap-2 border border-[#999999] rounded-[30px]"
            }
            textStyles={"text-text16 text-[#999999]"}
            handleClick={handleBackBtnClick}
          />

          <ReusableBtn
            icon={rightArrowIcon}
            text={"raports.nextBtn"}
            mainStyles={"w-[130px] h-[45px] bg-redPrimary gap-2 rounded-[30px]"}
            textStyles={"text-text16 text-[#F8F8F8]"}
            handleClick={handleNextBtnClick}
          />
        </div>
      </RaportGroup>

      <RaportGroup
        raportGroupIndex={raportGroupIndex}
        index={5}
        contentTitleLngId={"notifications.group6Title"}
        readyToGo={raportGroupIndex === 5}
      >
        <div className="space-y-4">
          <OverviewComponent
            lngId={"notification.group6Text1"}
            selectedValue={formData.name}
          ></OverviewComponent>

          <OverviewComponent
            lngId={"notification.group6Text2"}
            selectedValue={formData.description}
          ></OverviewComponent>

          <OverviewComponent
            lngId={"notification.group6Text3"}
            selectedValue={formData.assets.applyTo}
          ></OverviewComponent>

          <OverviewComponent
            lngId={"notification.group6Text4"}
            selectedValue={formData.assets.assetGroups}
            isArray={true}
          ></OverviewComponent>

          <OverviewComponent
            lngId={"notification.group6Text5"}
            selectedValue={formData.alertTypes.type}
          ></OverviewComponent>

          <OverviewComponent
            lngId={"notification.group6Text6"}
            selectedValue={formData.alertTypes.alert}
            isArray={true}
          ></OverviewComponent>

          <OverviewComponent
            lngId={"notification.group6Text7"}
            selectedValue={formData.users.applyTo}
          ></OverviewComponent>

          <OverviewComponent
            lngId={"notification.group6Text8"}
            selectedValue={formData.methodsAndMessage}
          ></OverviewComponent>

          <OverviewComponent
            lngId={"notification.group6Text9"}
            selectedValue={""}
          ></OverviewComponent>
        </div>

        <div className="next-btn">
          <ReusableBtn
            icon={leftArrowIcon}
            text={"raports.backBtn"}
            mainStyles={
              "w-[130px] h-[45px] gap-2 border border-[#999999] rounded-[30px]"
            }
            textStyles={"text-text16 text-[#999999]"}
            handleClick={handleBackBtnClick}
          />
        </div>
      </RaportGroup>
    </form>
  );
};

CreateNewNotification.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  handleChange: PropTypes.func,
};

export default CreateNewNotification;
