/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Table from "../Components/table/Table";
import PageTitle from "../../../components/Layout/PageTitle";
import ViewAsset from "../Components/ViewAsset/ViewAsset";
import { NotificationsTable, DeviceDetailTitles } from "../../../constants";
import plusIcon from "../../../assets/icons/Panel/administration/Assets/plus-icon.svg";
import funnelIcon from "../../../assets/icons/Panel/administration/Assets/funnel-icon.svg";
import discardIcon from "../../../assets/icons/Panel/administration/Assets/discardIcon.svg";
import editIconWhite from "../../../assets/icons/Panel/administration/AssetGroup/editIconWhite.svg";
import CreateNewNotification from "./Components/CreateNewNotification";
import ReusableBtn from "../../../components/ReusableBtn/ReusableBtn";

const Notifications = () => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    assets: {
      applyTo: "",
      assetGroups: [],
    },
    alertTypes: { type: "", alert: [] },
    users: {
      applyTo: "",
      selectedUsers: [],
    },
    methodsAndMessage: {
      PopUp: true,
      Browser: true,
      SMS: false,
      Email: false,
      Push: false,
    },
  });
  const [view, setView] = useState("table");
  const [rowData, setRowData] = useState(null);

  const bodyData = [
    {
      id: 0,
      data: [
        {
          id: 0,
          row: "sddfbgbgn",
        },
        {
          id: 1,
          row: "sddfbfd",
        },
        {
          id: 2,
          row: "6",
        },
        {
          id: 3,
          row: "gghrthrrthr",
        },
        {
          id: 4,
          row: "13/02/21  14:46",
        },
        {
          id: 5,
          row: "lorela.parruca1@vodafone.com",
        },
      ],
    },
  ];

  const handleChange = (event) => {
    const { id } = event.target;

    if (id === "name" || id === "description") {
      setFormData((prevValues) => ({
        ...prevValues,
        [id]: event.target.value,
      }));
    }

    if (id === "All assets" || id === "Specific") {
      setFormData((prevValues) => ({
        ...prevValues,
        assets: {
          ...prevValues.assets,
          applyTo: id,
        },
      }));
    }

    if (id === "All Alert Types" || id === "Specific Alert Types") {
      setFormData((prevValues) => ({
        ...prevValues,
        alertTypes: { ...prevValues.alertTypes, type: id },
      }));
    }

    if (id === "All Users" || id === "Multiple Users" || id === "User Groups") {
      setFormData((prevValues) => ({
        ...prevValues,
        users: {
          ...prevValues.users,
          applyTo: id,
        },
      }));
    }

    if (
      id === "PopUp" ||
      id === "Browser" ||
      id === "SMS" ||
      id === "Email" ||
      id === "Push"
    ) {
      setFormData((prevValues) => ({
        ...prevValues,
        methodsAndMessage: {
          ...prevValues.methodsAndMessage,
          [id]: !prevValues.methodsAndMessage[id],
        },
      }));
    }
  };

  const onSubmit = () => {
    console.log("this is formData:", formData);
  };

  return (
    <div className="pageLayout">
      <div
        className="w-full flex flex-col gap-2 lg:gap-0 lg:flex-row lg:justify-between lg:items-center bg-mainBg pt-5 z-10"
        style={{
          position: view === "create" && "sticky",
          top: view === "create" && "0px",
        }}
      >
        <PageTitle
          titleLngId={
            view === "create"
              ? "notifications.createNewNotificationTitle"
              : view === "view"
              ? "notifications.viewNotificationDetails"
              : view === "edit"
              ? "notifications.notificationsDetailsEdit"
              : "notifications.pageTitle"
          }
          subTitleLngId={"app.administrationPanel"}
          subTitle2LngId={"notifications.subtitle2"}
          state={view}
          setState={() => setView("table")}
        ></PageTitle>

        <div className=" flex items-center gap-2 mb-4 lg:mb-0">
          {view === "create" && (
            <ReusableBtn
              icon={discardIcon}
              text={"assets.createAssetBtn1"}
              mainStyles={
                "w-[100px] h-[45px] bg-transparent gap-2 px-1 border border-[#0D0D0D] rounded-md"
              }
              textStyles={"text-filterBtn text-[#0D0D0D]"}
              handleClick={() => setView("table")}
            />
          )}

          <ReusableBtn
            icon={
              view === "create"
                ? ""
                : view === "view"
                ? editIconWhite
                : plusIcon
            }
            text={
              view === "create"
                ? "notifications.btn2"
                : view === "table"
                ? "notifications.btn1"
                : "notifications.notificationsEditBtn"
            }
            mainStyles={"w-fit h-[45px] bg-redPrimary gap-2 px-4 rounded-md"}
            textStyles={"text-filterBtn text-[#FFFFFF]"}
            handleClick={
              view === "create"
                ? onSubmit
                : view === "view"
                ? () => console.log("edit page")
                : () => setView("create")
            }
          />

          {view === "table" && (
            <ReusableBtn
              icon={funnelIcon}
              text={"(81)"}
              mainStyles={"h-[45px] bg-white gap-1 px-3 rounded-md"}
              textStyles={"text-textSecondary"}
              handleClick={() => console.log("")}
            />
          )}
        </div>
      </div>

      {view === "create" && (
        <CreateNewNotification
          formData={formData}
          handleChange={handleChange}
          setFormData={setFormData}
        ></CreateNewNotification>
      )}

      {view === "view" && (
        <ViewAsset
          titleLngId={"notifications.notificationsDetailsText"}
          titles={DeviceDetailTitles}
          data={rowData}
        ></ViewAsset>
      )}

      {view === "edit" && <div>Edit</div>}

      {view === "table" && (
        <Table
          version={1}
          headerData={NotificationsTable}
          bodyData={bodyData}
          setState={setView}
          setRowData={setRowData}
        />
      )}
    </div>
  );
};

export default Notifications;
