import React from "react";
// import { PropTypes } from "prop-types";

import { FormattedMessage } from "react-intl";

const ViewUserDetails = () => {
  return (
    <div id="viewUserDetails" className="formContainer">
      <div className="groupContainer">
        <h1 className=" font-bold text-inputsGroupTitle text-pageTitle">
          <FormattedMessage id="users.userDetailsText" />
        </h1>

        <div className=" w-full flex flex-col gap-2 p-4 text-[#222222] text-[18px] bg-white rounded-md border border-[#EDEFF2] ">
          <div className="flex justify-between items-center">
            <FormattedMessage id="users.detailsField1" />
            <span>Ada</span>
          </div>
          <div className="flex justify-between items-center">
            <FormattedMessage id="users.detailsField2" />
            <span>Nakuci</span>
          </div>
          <div className="flex justify-between items-center">
            <FormattedMessage id="users.detailsField3" />
            <span>-</span>
          </div>
          <div className="flex justify-between items-center">
            <FormattedMessage id="users.detailsField4" />
            <span>+355692226650</span>
          </div>
          <div className="flex justify-between items-center">
            <FormattedMessage id="users.detailsField5" />
            <span>(ALB) Fleet Customer</span>
          </div>
          <div className="flex justify-between items-center">
            <FormattedMessage id="users.detailsField6" />
            <span>-</span>
          </div>
          <div className="flex justify-between items-center">
            <FormattedMessage id="users.detailsField7" />
            <span>-</span>
          </div>
          <div className="flex justify-between items-center">
            <FormattedMessage id="users.detailsField8" />
            <span>-</span>
          </div>
        </div>
      </div>

      <div className="groupContainer">
        <h1 className=" font-bold text-inputsGroupTitle text-pageTitle">
          <FormattedMessage id="users.userDetailsText2" />
        </h1>

        <div className=" w-full flex flex-col gap-2 p-4 text-[#222222] text-[18px] bg-white rounded-md border border-[#EDEFF2] ">
          <div className="flex justify-between items-center">
            <FormattedMessage id="users.detailsField9" />
            <span>ada.nakuci@adc.al</span>
          </div>

          <div className="flex justify-between items-center">
            <FormattedMessage id="users.detailsField10" />
            <span>23/08/22 09:11</span>
          </div>
        </div>
      </div>

      <div className="groupContainer">
        <h1 className=" font-bold text-inputsGroupTitle text-pageTitle">
          <FormattedMessage id="users.userDetailsText4" />
        </h1>

        <div className=" w-full flex flex-col gap-2 p-4 text-[#222222] text-[18px] bg-white rounded-md border border-[#EDEFF2] ">
          <div className="flex justify-between items-center">
            <FormattedMessage id="users.detailsField11" />
            <span>Europe/Tirane</span>
          </div>

          <div className="flex justify-between items-center">
            <FormattedMessage id="users.detailsField12" />
            <span>English - United Kingdom</span>
          </div>
          <div className="flex justify-between items-center">
            <FormattedMessage id="users.detailsField13" />
            <span>Albania</span>
          </div>
          <div className="flex justify-between items-center">
            <FormattedMessage id="users.detailsField14" />
            <span>Yes</span>
          </div>
        </div>
      </div>

      <div className="groupContainer">
        <h1 className=" font-bold text-inputsGroupTitle text-pageTitle">
          <FormattedMessage id="users.userDetailsText4" />
        </h1>

        <div className=" w-full flex flex-col gap-2 p-4 text-[#222222] text-[18px] bg-white rounded-md border border-[#EDEFF2] ">
          <div className="flex justify-between items-center">
            <FormattedMessage id="users.detailsField15" />
            <span>-</span>
          </div>

          <div className="flex justify-between items-center">
            <FormattedMessage id="users.detailsField16" />
            <span>-</span>
          </div>
          <div className="flex justify-between items-center">
            <FormattedMessage id="users.detailsField17" />
            <span>-</span>
          </div>
        </div>
      </div>
    </div>
  );
};

ViewUserDetails.propTypes = {};

export default ViewUserDetails;
