/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import PageTitle from "../../../components/Layout/PageTitle";
import Table from "../Components/table/Table";
import { BulkUploadsTable } from "../../../constants";
import plusIcon from "../../../assets/icons/Panel/administration/Assets/plus-icon.svg";
import funnelIcon from "../../../assets/icons/Panel/administration/Assets/funnel-icon.svg";
import AddBulkUpload from "./components/AddBulkUpload";
import ReusableBtn from "../../../components/ReusableBtn/ReusableBtn";
import AddTemplate from "./components/AddTemplate";

const BulkUploads = () => {
  const [view, setView] = useState("table");
  const [addTemplate, setAddTemplate] = useState(false);

  const handleBtnActions = (event) => {
    const { id } = event.target;

    if (id === "template") {
      setAddTemplate((prev) => !prev);
    } else {
      setView(id);
    }
  };

  return (
    <div className="pageLayout">
      <div className="w-full flex flex-col gap-2 lg:gap-0 lg:flex-row lg:justify-between lg:items-center bg-mainBg pt-5 z-10">
        <PageTitle
          titleLngId={
            view === "create" ? "bulkUploads.btn1" : "bulkUploads.pageTitle"
          }
          subTitleLngId={"app.administrationPanel"}
          subTitle2LngId={"bulkUploads.subtitle2"}
          state={view}
          setState={() => setView("table")}
        ></PageTitle>

        {view === "table" && (
          <div className=" flex items-center gap-2 mb-4 lg:mb-0">
            <ReusableBtn
              id={"template"}
              icon={plusIcon}
              imgWidth={"14px"}
              mainStyles={"w-fit h-[45px] px-4 gap-2 bg-[#007C92] rounded-md"}
              textStyles={"text-filterBtn text-[#FFFFFF]"}
              text={"bulkUploads.btn2"}
              handleClick={handleBtnActions}
            />

            <ReusableBtn
              id={"create"}
              icon={plusIcon}
              imgWidth={"14px"}
              mainStyles={"w-fit h-[45px] px-4 gap-2 bg-redPrimary rounded-md"}
              textStyles={"text-filterBtn text-[#FFFFFF]"}
              text={"bulkUploads.btn1"}
              handleClick={handleBtnActions}
            />

            <ReusableBtn
              icon={funnelIcon}
              text={"(81)"}
              imgWidth={"14px"}
              mainStyles={"w-fit h-[45px] px-3 gap-1 bg-white rounded-md"}
              textStyles={"text-textSecondary"}
              handleClick={() => console.log("")}
            />
          </div>
        )}
      </div>

      {view === "create" && (
        <AddBulkUpload handleBtnActions={handleBtnActions} />
      )}

      {addTemplate && <AddTemplate handleBtnActions={handleBtnActions} />}

      {view === "table" && (
        <Table headerData={BulkUploadsTable} setState={setView} />
      )}
    </div>
  );
};

export default BulkUploads;
