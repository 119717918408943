/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";

const TextInput = ({
  mainStyles,
  inputClassName,
  labelStyle,
  placeholderLngId,
  labelLngId,
  id,
  formData,
  handleChange,
  icon,
}) => {
  return (
    <div className={`flex flex-col gap-2 ${mainStyles}`}>
      {labelLngId && (
        <label htmlFor={id} className={labelStyle}>
          <FormattedMessage id={labelLngId} />
        </label>
      )}

      <div className={inputClassName}>
        <FormattedMessage id={placeholderLngId}>
          {(placeholder) => (
            <input
              id={id}
              type={"text"}
              required
              placeholder={placeholder}
              className={`w-full h-full outline-none placeholder-shown:text-ellipsis bg-transparent`}
              value={formData}
              onChange={handleChange}
            />
          )}
        </FormattedMessage>

        {icon && (
          <img
            src={icon}
            width={16}
            className="mr-3 cursor-pointer active:opacity-70"
          ></img>
        )}
      </div>
    </div>
  );
};

TextInput.propTypes = {
  placeholderLngId: PropTypes.string,
  inputClassName: PropTypes.string,
  formData: PropTypes.string,
  handleChange: PropTypes.func,
  labelLngId: PropTypes.string,
  id: PropTypes.string,
  mainStyles: PropTypes.string,
  labelStyle: PropTypes.string,
  icon: PropTypes.string,
};

export default TextInput;
