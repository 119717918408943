/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */
/*
Version 1 : simple table with 3 dots for : View, Edit, Delete. Ex: Assets, Asset Groups, Devices,Notifications.
Version 2 : table with selectable option and 3 dots for view, edit,add to group, move to role,
            assign assets, reset password, delete. Ex: UserGroup
Version 3 : simple table with a colorful status. Ex: Raports
Version 4 : simple table with checkbox. Ex: CreateNewRaport
Version 5 : table with 3 dots for Go to Asset Page, Show on map, and star and pin option Ex: Network
Version 6 : table with 3 dots for Edit, Delete, and Checkbox in the end for active or not
*/

/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from "react";
import { PropTypes } from "prop-types";

import DotsWindow from "./DotsWindow";
import { FormattedMessage } from "react-intl";
import Checkbox from "../../../../components/Inputs/Checkbox";

import dotsIcon from "../../../../assets/icons/Panel/administration/TableIcons/dotsIcon.svg";
import mailIcon from "../../../../assets/icons/Panel/administration/TableIcons/mailIcon.svg";
import phoneIcon from "../../../../assets/icons/Panel/administration/TableIcons/phoneIcon.svg";
import excelIcon from "../../../../assets/icons/Panel/Raports/excelIcon.svg";
import pdfIcon from "../../../../assets/icons/Panel/Raports/pdfIcon.svg";
import sensorIcon from "../../../../assets/icons/Panel/network/sensorIcon.svg";
import emptyStar from "../../../../assets/icons/Panel/network/emptyStar.svg";
import pinIcon from "../../../../assets/icons/Panel/network/pinIcon.svg";

const Table = ({
  headerData,
  bodyData,
  setState,
  setRowData,
  version,
  setSelectedRows,
  selectedRows,
  isChecked,
  setIsChecked,
  shadow,
}) => {
  const [rowIndex, setRowIndex] = useState(null);

  const openWindow = (index) => {
    if (index === rowIndex) {
      setRowIndex(null);
    } else {
      setRowIndex(index);
    }
  };

  const handleCheckboxChange = useCallback(
    (item) => {
      const { name } = event.target;

      if (name === "all") {
        if (isChecked.all) {
          setIsChecked({
            all: false,
          });

          setSelectedRows([]);
        } else {
          setIsChecked({ all: true });

          setSelectedRows([...bodyData]);
        }
      } else {
        if (isChecked.all === true) {
          setIsChecked({
            all: false,
            [item.id]: true,
          });

          const filtered = bodyData.filter((element) => element.id === item.id);

          setSelectedRows([...filtered]);
        } else {
          if (isChecked[item.id] === true) {
            setIsChecked((prevValues) => ({
              ...prevValues,
              [item.id]: false,
            }));

            const filtered = selectedRows.filter(
              (element) => element.id !== item.id
            );

            setSelectedRows([...filtered]);
          } else {
            setIsChecked((prevValues) => ({
              ...prevValues,
              [item.id]: true,
            }));

            setSelectedRows((prevValues) => [...prevValues, item]);
          }
        }
      }
    },
    [bodyData, isChecked, selectedRows, setIsChecked, setSelectedRows]
  );

  return (
    <div>
      <div className={`table-container ${!shadow ? "shadow-md" : "shadow-sm"}`}>
        <table className={`${version !== 4 && "table-auto"}`}>
          <thead className="assets-table-head">
            <tr>
              {version === 2 && (
                <th>
                  <Checkbox
                    name={"all"}
                    id={"all"}
                    handleChange={() => handleCheckboxChange("all")}
                    checked={isChecked.all === true}
                    disabled={bodyData?.length > 0 ? false : true}
                  />
                </th>
              )}
              {headerData.map((item, index) => (
                <th
                  scope="col"
                  key={item.id}
                  style={{
                    paddingLeft: version === 4 && index === 0 && "70px",
                  }}
                >
                  {item.title}
                </th>
              ))}
            </tr>
          </thead>

          <tbody className="table-body">
            {bodyData && bodyData.length > 0 ? (
              bodyData.map((item) => (
                <tr
                  key={item.id}
                  style={{
                    backgroundColor:
                      (version === 2 || version === 4) &&
                      (isChecked[item.id] === true || isChecked.all === true) &&
                      "rgba(0,0,0,0.01)",
                  }}
                >
                  {/* Showing checkbox in the first td */}
                  {version === 2 && (
                    <td>
                      <Checkbox
                        id={"specific"}
                        name={"specific"}
                        handleChange={() => handleCheckboxChange(item)}
                        checked={
                          isChecked.all === true || isChecked[item.id] === true
                        }
                      />
                    </td>
                  )}

                  {/* Showing 3dots  in the first td */}
                  {(version === 1 ||
                    version === 2 ||
                    version === 5 ||
                    version === 6) && (
                    <td>
                      <span
                        onClick={() => openWindow(item.id)}
                        className="img-container"
                      >
                        <img src={dotsIcon}></img>
                      </span>
                      {item.id === rowIndex && (
                        <DotsWindow
                          setState={setState}
                          setRowData={setRowData}
                          data={item.data}
                          version={version}
                        ></DotsWindow>
                      )}
                    </td>
                  )}

                  {/* Showing star nd pin icon in the second td */}
                  {version === 5 && (
                    <td className="flex w-[100px] items-center justify-center gap-2">
                      <img
                        src={emptyStar}
                        alt={emptyStar}
                        className="px-2 cursor-pointer border-l"
                      ></img>
                      <img
                        src={pinIcon}
                        alt={pinIcon}
                        className="px-2 cursor-pointer"
                      ></img>
                    </td>
                  )}

                  {/* Mapping and showing available datas */}
                  {item.data.map((element, index) => (
                    <td
                      key={element.id}
                      style={{
                        fontWeight: element.id === 0 && "bold",
                        color: element.id === 1 && "#9AA8B3",
                      }}
                    >
                      {version === 5 && index === 5 ? (
                        // Using Altitude, Angle and Available with the sensor icon for version 5 at index 5
                        <div className="flex h-full text-regularSize">
                          <div className="flex justify-center items-center gap-2 border-l border-inputBg w-[100px]">
                            <img src={sensorIcon}></img>
                            <div className="flex flex-col">
                              <span className="text-[#ADB7C2]">
                                <FormattedMessage id="table9.sensorsText1" />
                              </span>
                              <span className="text-[#8A98A8] font-bold">
                                {element.row[0]}m
                              </span>
                            </div>
                          </div>
                          <div className="flex justify-center items-center gap-2 px-2 border-l border-inputBg w-[90px]">
                            <img src={sensorIcon}></img>
                            <div className="flex flex-col">
                              <span className="text-[#ADB7C2]">
                                <FormattedMessage id="table9.sensorsText2" />
                              </span>
                              <span className="text-[#8A98A8] font-bold">
                                {element.row[1]}
                              </span>
                            </div>
                          </div>
                          <div className="flex justify-center items-center gap-2 px-2 border-l border-inputBg w-[120px]">
                            <img src={sensorIcon}></img>
                            <div className="flex flex-col truncate">
                              <span className="truncate text-[#ADB7C2]">
                                <FormattedMessage id="table9.sensorsText3" />
                              </span>
                              <span className="text-[#8A98A8] font-bold">
                                {element.row[2]}
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : version === 5 && index === 7 ? (
                        // Using truncate for the text in index 7 and version 5, ex: Kruje,Durres, ...
                        <div className="h-full flex items-center w-[100px] truncate">
                          <span className="truncate">{element.row}</span>
                        </div>
                      ) : version === 5 && index === 8 ? (
                        // Showing date and time in flex-col in the last td
                        <div className="h-full flex flex-col justify-center w-[80px] truncate">
                          <span>{element.row[0]}</span>
                          <span>{element.row[1]}</span>
                        </div>
                      ) : (
                        // Showing checkbox in the first row , with the first data value in a single (td)
                        <div
                          style={{
                            display: version === 4 && "flex",
                            gap: version === 4 && "30px",
                            alignItems: version === 4 && "center",
                            width:
                              version === 3 &&
                              element.row === "Ready" &&
                              "fit-content",
                            padding:
                              version === 3 &&
                              element.row === "Ready" &&
                              "5px 10px",
                            backgroundColor:
                              version === 3 &&
                              element.row === "Ready" &&
                              "#66CE66",
                            color:
                              version === 3 &&
                              element.row === "Ready" &&
                              "white",
                            borderRadius:
                              version === 3 &&
                              element.row === "Ready" &&
                              "15px",
                          }}
                        >
                          {index === 0 && version === 4 && (
                            <Checkbox
                              id={"specific"}
                              name={"specific"}
                              handleChange={() => handleCheckboxChange(item)}
                              checked={
                                isChecked.all === true ||
                                isChecked[item.id] === true
                              }
                            />
                          )}
                          {element.row}
                        </div>
                      )}
                    </td>
                  ))}

                  {/* Showing at the end of the row : email & phone icon for version 2 (Raports), and excel & pdf icon for version 3 (user groups)*/}
                  {(version === 3 || version === 2) && (
                    <td className=" flex items-center gap-2">
                      <img
                        src={version === 2 ? mailIcon : excelIcon}
                        className="active:opacity-70 cursor-pointer"
                      ></img>
                      <img
                        src={version === 2 ? phoneIcon : pdfIcon}
                        className="active:opacity-70 cursor-pointer"
                      ></img>
                    </td>
                  )}

                  {/* Showing the checkbox in the last td of the row */}
                  {version === 6 && (
                    <td>
                      <Checkbox checked={true}></Checkbox>
                    </td>
                  )}
                </tr>
              ))
            ) : (
              // If no data available, show no results text
              <tr>
                <td
                  colSpan={
                    version === 2 ? headerData.length + 1 : headerData.length
                  }
                >
                  <FormattedMessage id="table.noResultText" />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

Table.propTypes = {
  headerData: PropTypes.object,
  bodyData: PropTypes.object,
  setState: PropTypes.func,
  setRowData: PropTypes.func,
  setSelectedRows: PropTypes.func,
  selectedRows: PropTypes.object,
  version: PropTypes.number,
  isChecked: PropTypes.object,
  setIsChecked: PropTypes.func,
  shadow: PropTypes.boolean,
};

export default React.memo(Table);
