import React from "react";
import { PropTypes } from "prop-types";
import TimeInput from "../../../../components/Inputs/TimeInput";
import ReusableBtn from "../../../../components/ReusableBtn/ReusableBtn";

const DayOfTheWeek = ({ day, text, formData, handleChange }) => {
  return (
    <div className="space-y-2">
      <h2 className="text-text18 font-bold">{text}</h2>
      <div className="flex gap-2 sm:gap-4">
        <TimeInput
          id={"from"}
          labelLngId={"raport.fromDate"}
          mainStyles
          labelStyles={"text-extraSmall text-textSecondary"}
          handleChange={(event) => handleChange(event, day)}
          value={formData[day].from}
        />
        <TimeInput
          id={"to"}
          labelLngId={"raport.fromDate"}
          mainStyles
          labelStyles={"text-extraSmall text-textSecondary"}
          handleChange={(event) => handleChange(event, day)}
          value={formData[day].to}
        />

        <ReusableBtn
          id={"clear"}
          mainStyles={
            "w-fit px-2 min-w-[80px] h-[53px] gap-2 bg-white border border-redPrimary rounded-md"
          }
          textStyles={"text-regularSize text-redPrimary"}
          text={"timetables.btn3"}
          handleClick={() => handleChange(event, day)}
        />
      </div>
    </div>
  );
};

DayOfTheWeek.propTypes = {
  day: PropTypes.string,
  text: PropTypes.string,
  formData: PropTypes.object,
  handleChange: PropTypes.func,
};

export default DayOfTheWeek;
