import React from "react";
import { FormattedMessage } from "react-intl";

const DrawingInstructions = () => {
  return (
    <div className="p-4 rounded-md bg-[#007C92] flex flex-col gap-2 text-[rgba(255,255,255,0.9)] text-extraSmall font-light">
      <div className="font-bold text-white">
        <FormattedMessage id="pois.drawingsInstructionsTitle" />
      </div>
      <ul className=" list-decimal space-y-1">
        <li className="ml-4 w-full md:max-w-[80%]">
          <FormattedMessage id="pois.drawingsInstructions1" />
        </li>
        <li className="ml-4 w-full md:max-w-[80%]">
          <FormattedMessage id="pois.drawingsInstructions2" />
        </li>
        <li className="ml-4 w-full md:max-w-[80%]">
          <FormattedMessage id="pois.drawingsInstructions3" />
        </li>
      </ul>
    </div>
  );
};

export default DrawingInstructions;
