/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import { PropTypes } from "prop-types";

const AlertType = ({ icon, label, setFormData }) => {
  const [isSelected, setIsSelected] = useState(false);

  const handleSelect = useCallback(() => {
    setIsSelected((prev) => !prev);
  }, []);

  useEffect(() => {
    if (isSelected) {
      setFormData((prev) => ({
        ...prev,
        alertTypes: {
          ...prev.alertTypes,
          alert: [...prev.alertTypes.alert, label],
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        alertTypes: {
          ...prev.alertTypes,
          alert: prev.alertTypes.alert.filter((item) => item !== label),
        },
      }));
    }
  }, [isSelected, label, setFormData]);

  return (
    <div
      className="flex flex-col p-2 gap-3 w-fit h-fit items-center cursor-pointer hover:opacity-80 active:opacity-70"
      onClick={handleSelect}
    >
      <div
        className=" size-[35px] rounded-[5px] flex justify-center items-center rotate-45"
        style={{ backgroundColor: isSelected ? "#007C92" : "#9AA8B3" }}
      >
        <img src="" alt=""></img>
      </div>

      <span className=" text-xs uppercase text-darkGray">{label}</span>
    </div>
  );
};

AlertType.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  setFormData: PropTypes.func,
};

export default AlertType;
