import React, { useRef } from "react";
import { PropTypes } from "prop-types";
import { FormattedMessage } from "react-intl";
import clockIcon from "../../assets/icons/Panel/administration/Timetable/clockIcon.svg";

const TimeInput = ({
  id,
  labelLngId,
  mainStyles,
  labelStyles,
  handleChange,
  value,
}) => {
  const inputRef = useRef();
  const showPicker = () => {
    inputRef.current?.showPicker();
  };

  return (
    <div
      className={`w-full bg-white rounded-md h-[55px] flex flex-col py-1 px-3 relative ${mainStyles}`}
      onClick={showPicker}
    >
      <label className={`${labelStyles}`}>
        <FormattedMessage id={labelLngId} />
      </label>
      <div className="flex items-center gap-1 sm:gap-2 h-full">
        <img src={clockIcon} alt={clockIcon} className=" size-4" />
        <input
          ref={inputRef}
          id={id}
          className=" outline-none truncate flex-1 text-extraSmall sm:text-text16"
          value={value}
          onChange={handleChange}
          type={"time"}
        />
      </div>
    </div>
  );
};

TimeInput.propTypes = {
  id: PropTypes.string,
  labelLngId: PropTypes.string,
  handleChange: PropTypes.func,
  value: PropTypes.string,
  labelStyles: PropTypes.string,
  mainStyles: PropTypes.string,
};

export default TimeInput;
