import React from "react";
import { PropTypes } from "prop-types";

import { FormattedMessage } from "react-intl";

const ViewAsset = ({ titles, data, titleLngId }) => {
  return (
    <div className="flex flex-col gap-4 py-3">
      <h3 className=" font-bold text-[20px]">
        <FormattedMessage id={titleLngId} />
      </h3>

      <div className=" w-full text-[#222222] text-[18px] bg-white rounded-md overflow-hidden border-x border-t border-[#EDEFF2] ">
        {titles.map((item, index) => (
          <div
            key={index}
            className="flex justify-between items-center py-2 px-4 border-b border-[#EDEFF2]"
            style={{
              backgroundColor: index % 2 === 1 ? "#FBFBFB" : "#FFFFFF",
            }}
          >
            <div>{item}</div>
            <div>{data[index]?.row}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

ViewAsset.propTypes = {
  titles: PropTypes.Object,
  data: PropTypes.Object,
  titleLngId: PropTypes.string,
};

export default ViewAsset;
