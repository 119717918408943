import React, { useState } from "react";
import Table from "../Administration/Components/table/Table";
import PageTitle from "../../components/Layout/PageTitle";
import CreateNewRaport from "./Components/CreateNewRaport";
import { RaportsTable } from "../../constants";
import plusIcon from "../../assets/icons/Panel/administration/Assets/plus-icon.svg";
import ReusableBtn from "../../components/ReusableBtn/ReusableBtn";

const bodyData = [
  {
    id: 0,
    data: [
      {
        id: 0,
        row: "Sensor Value History",
      },
      {
        id: 1,
        row: "09/04/24 11:24",
      },
      {
        id: 2,
        row: "09/04/24 11:24",
      },
      {
        id: 3,
        row: "operations@adc.al",
      },
      {
        id: 4,
        row: "",
      },
      {
        id: 5,
        row: "Ready",
      },
      {
        id: 6,
        row: "in 7 days",
      },
    ],
  },
];

const Raports = () => {
  const [view, setView] = useState("table");

  return (
    <div className="pageLayout">
      <div
        className="w-full flex flex-col gap-2 lg:gap-0 lg:flex-row lg:justify-between lg:items-center bg-mainBg pt-5 z-10"
        style={{
          position: view === "create" && "sticky",
          top: view === "create" && "0px",
        }}
      >
        <PageTitle
          titleLngId={"raports.pageTitle"}
          subTitleLngId={"raports.subtitle1"}
          subTitle2LngId={"raports.subtitle2"}
          // state={view}
          // setState={() => setView("table")}
        ></PageTitle>

        {view === "table" && (
          <ReusableBtn
            icon={plusIcon}
            text={"raports.btn1"}
            mainStyles={"w-fit h-[45px] bg-redPrimary px-4 gap-2 rounded-md"}
            textStyles={"text-[#FFFFFF] text-filterBtn"}
            handleClick={() => setView("create")}
          />
        )}
      </div>

      {view === "create" && <CreateNewRaport setView={setView} />}

      {/* {view === "create" && (
        <div className="flex bg-mainBg items-center p-4 border-t justify-center md:justify-end mt-5 gap-5 absolute bottom-0 right-5 left-5">
          <ReusableBtn
            id={"table"}
            mainStyles={
              "w-[106px] h-[45px] bg-white rounded-md border-2 border-blackPrimary"
            }
            textStyles={"text-text18"}
            text={"bulkUploads.discard"}
            // handleClick={handleBtnActions}
            // handleClick={handleBtnClick}
          />

          <ReusableBtn
            // id={"create"}
            type={"submit"}
            // imgWidth={"14px"}
            // mainStyles={"w-[106px] h-[45px] bg-redPrimary rounded-md"}
            // textStyles={"text-text18 text-[#FFFFFF]"}
            mainStyles={"w-fit h-[45px] bg-redPrimary px-4 gap-2 rounded-md"}
            textStyles={"text-[#FFFFFF] text-filterBtn"}
            text={"raports.createRaportTitle"}
            // disabled={isUploading}
          />
        </div>
      )} */}

      {view === "table" && (
        <Table
          version={3}
          headerData={RaportsTable}
          bodyData={bodyData}
          setState={setView}
        />
      )}
    </div>
  );
};

export default Raports;
