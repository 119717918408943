import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

// Version 1 : icon on the left
// Version 2 : icon on the right

const ReusableBtn = ({
  mainStyles,
  textStyles,
  id,
  icon,
  text,
  type,
  handleClick,
  rotate,
  imgWidth,
  version,
  disabled,
}) => {
  return (
    <button
      id={id}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      type={type ? type : "button"}
      onClick={handleClick}
      className={`hover:opacity-90 active:opacity-80 ${mainStyles}`}
      disabled={disabled ? disabled : false}
    >
      {icon && version !== 2 && (
        <img
          src={icon}
          width={imgWidth}
          style={{ transform: rotate && `rotate(${rotate})` }}
        ></img>
      )}
      {text && (
        <h3 className={`${textStyles} pointer-events-none`}>
          <FormattedMessage id={text} />
        </h3>
      )}

      {icon && version === 2 && (
        <img
          src={icon}
          width={imgWidth}
          style={{ transform: rotate && `rotate(${rotate})` }}
        ></img>
      )}
    </button>
  );
};

ReusableBtn.propTypes = {
  id: PropTypes.string,
  mainStyles: PropTypes.string,
  textStyles: PropTypes.string,
  icon: PropTypes.string,
  text: PropTypes.object,
  type: PropTypes.string,
  handleClick: PropTypes.func,
  rotate: PropTypes.string,
  imgWidth: PropTypes.string,
  version: PropTypes.number,
  disabled: PropTypes.bool,
};

export default ReusableBtn;
