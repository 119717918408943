/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const Checkbox = ({
  labelLngId,
  labelSize,
  labelColor,
  name,
  inputId,
  checked,
  disabled,
  handleChange,
}) => {
  return (
    <label
      className="container"
      style={{
        paddingLeft: labelLngId && "25px",
        marginBottom: labelLngId && "3px",
      }}
    >
      {labelLngId && (
        <span className={`${labelSize} ${labelColor}`}>
          <FormattedMessage id={labelLngId} />
        </span>
      )}
      <input
        className=" hidden"
        type="checkbox"
        name={name}
        id={inputId}
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
      />
      <span
        className="checkmark"
        style={{
          position: labelLngId && "absolute",
          top: labelLngId && "4px",
          left: labelLngId && 0,
        }}
      ></span>
    </label>
  );
};

Checkbox.propTypes = {
  labelLngId: PropTypes.string,
  labelSize: PropTypes.string,
  labelColor: PropTypes.string,
  name: PropTypes.string,
  inputId: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func,
};

export default Checkbox;
