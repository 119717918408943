/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from "react";
import dayjs from "dayjs";

import { IoIosArrowDown } from "react-icons/io";
import hamburgerIcon from "../../../assets/icons/Panel/maintenance/hamburgerIcon.svg";
import leftArrow from "../../../assets/icons/Panel/maintenance/leftArrow.svg";
import rightArrow from "../../../assets/icons/Panel/maintenance/rightArrow.svg";

import { useDispatch, useSelector } from "react-redux";
import {
  setMonthIndex,
  toggleSmallCalendar,
  increment,
  decrement,
} from "../../../store/calendar/calendarSlice.js";

const monthsArray = [
  "January",
  "Feb",
  "Mars",
  "April",
  "May",
  "June",
  "July",
  "August",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const CalendarHeader = () => {
  const [toggleSelectMonth, setToggleSelectMonth] = useState(false);
  const [selectedMonthTitle, setSelectedMonthTitle] = useState("Month");
  const dispatch = useDispatch();
  const monthIndex = useSelector((state) => state.calendar.monthIndex);

  console.log("This is monthIndex:", monthIndex);

  const toggleMonthSelection = () => {
    setToggleSelectMonth((prevValue) => !prevValue);
  };

  const handleMonthSelection = (index) => {
    dispatch(setMonthIndex(index));

    setSelectedMonthTitle(monthsArray[index]);
  };

  const handleSmallCalendarState = () => {
    dispatch(toggleSmallCalendar());
  };

  const handleClick = useCallback(
    (event) => {
      const { id } = event.target;

      if (id === "leftArrow") {
        dispatch(decrement());
      }

      if (id === "rightArrow") {
        dispatch(increment());
      }
    },
    [dispatch]
  );

  return (
    <header className="px-2 md:px-5 py-2 flex gap-2 items-center justify-between">
      <div className="flex items-center gap-2">
        <img
          src={hamburgerIcon}
          alt="hamburgerIcon"
          className="cursor-pointer active:opacity-70 hidden md:flex"
          onClick={handleSmallCalendarState}
        />

        <h2 className=" md:ml-4 text-h2 text-blackPrimary font-medium">
          {dayjs(new Date(dayjs().year(), monthIndex)).format("MMMM YYYY")}
        </h2>

        <div className="flex items-center gap-2">
          <img
            src={leftArrow}
            alt={leftArrow}
            id="leftArrow"
            onClick={handleClick}
            className="cursor-pointer p-2"
          ></img>

          <img
            src={rightArrow}
            alt={rightArrow}
            onClick={handleClick}
            id="rightArrow"
            className="cursor-pointer  p-2"
          ></img>
        </div>
      </div>

      <div
        className="border border-[#0D0D0D] rounded-md p-2 ml-3 flex items-center justify-center gap-1 relative h-[35px] w-[96px]"
        onClick={toggleMonthSelection}
      >
        <span>{selectedMonthTitle}</span>
        <IoIosArrowDown />

        {toggleSelectMonth && (
          <div className="flex flex-col absolute top-12 left-0 right-0 bg-white border z-[9999999999999999]">
            {monthsArray.map((item, index) => (
              <button
                type="button"
                key={index}
                className={`p-1 text-left hover:bg-blue-200 ${
                  monthIndex === index && "bg-red-500"
                }`}
                onClick={() => handleMonthSelection(index)}
              >
                {item}
              </button>
            ))}
          </div>
        )}
      </div>
    </header>
  );
};

export default CalendarHeader;
