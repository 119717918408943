/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import TextInput from "../../components/Inputs/TextInput";
import VFLogo from "../../assets/logo/VDF-logo.png";
import ReusableBtn from "../../components/ReusableBtn/ReusableBtn";

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (event) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    console.log("This is formData:", formData);
  };

  return (
    <main className="w-full h-screen lg:flex relative">
      <div className="w-full h-full p-5 xl:w-[40%] flex justify-center items-center">
        <img
          src={VFLogo}
          alt="vodafone-business-logo"
          className="w-[120px]  absolute top-[30px] left-[35px] cursor-pointer"
        ></img>

        <form
          onSubmit={handleSubmit}
          className="w-full max-w-[400px] flex flex-col gap-5"
        >
          <div className="py-5">
            <h1 className=" text-h1 font-albertSansBold text-blackPrimary mb-1">
              <FormattedMessage id="login.title" />
            </h1>
            <p className="text-[16px] sm:text-p text-textSecondary ">
              <FormattedMessage id="login.subtitle" />
            </p>
          </div>

          <TextInput
            width={"100%"}
            maxWidth={""}
            height={"60px"}
            maxHeight={""}
            paddingHorizontal={"px-5"}
            marginHorizontal={""}
            background={"bg-inputBg"}
            borderColor={""}
            labelSize={"text-p"}
            labelColor={"text-blackPrimary"}
            labelWeight={""}
            placeholderLngId={"login.emailInput.placeholder"}
            labelLngId={"login.emailText"}
            type={"email"}
            name={"email"}
            id={"email-input"}
            formData={formData.email}
            handleChange={handleChange}
          />

          <TextInput
            width={"100%"}
            maxWidth={""}
            height={"60px"}
            maxHeight={""}
            paddingHorizontal={"px-5"}
            marginHorizontal={""}
            background={"bg-inputBg"}
            borderColor={""}
            labelSize={"text-p"}
            labelColor={"text-blackPrimary"}
            labelWeight={""}
            placeholderLngId={"login.passInput.placeholder"}
            labelLngId={"login.passwordText"}
            type="password"
            name="password"
            inputId={"pass-input"}
            handleChange={handleChange}
            formData={formData.password}
          />

          <div className=" flex  gap-3 py-1">
            <input type="checkbox" className="w-5"></input>
            <label className=" text-textSecondary ">
              <FormattedMessage id="login.rememberMe" />
            </label>
          </div>

          <ReusableBtn
            type={"submit"}
            text={"login.loginBtn"}
            mainStyles={"w-full h-[60px] bg-redPrimary rounded-md"}
            textStyles={"text-[#FFFFFF] text-btnTextSize"}
            handleClick={() => console.log("")}
          />
        </form>

        {/* <TwoFA></TwoFA> */}
      </div>

      <div className="hidden xl:flex xl:w-[60%] vfMapImage relative">
        <div className="absolute top-[125px] left-[70px] max-w-[420px] flex flex-col gap-3">
          <h1 className=" text-h2 font-albertSansSemiBold text-white">
            <FormattedMessage id="login.title2" />
          </h1>
          <p className=" text-p text-white">
            <FormattedMessage id="login.subtitle2" />
          </p>
        </div>
      </div>
    </main>
  );
};

export default Login;
