/* eslint-disable complexity */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { useWindowDimensions } from "../../utils/GetDimensions";

import {
  MenuItems,
  MenuItems2,
  MenuItems3,
  MenuItems4,
  AdministrationItems,
  AdministrationItems2,
  AdministrationItems3,
  AdministrationItems4,
} from "../../constants";

import MenuBtn from "./MenuBtn";
import HorizontalLine from "./HorizontalLine";

import arrow_backward from "../../assets/icons/Panel/arrow_backward.svg";

const Menu = ({ openSideMenu, toggleLogout }) => {
  const [toggleAdministrationMenu, setToggleAdministrationMenu] =
    useState(false);

  const { width } = useWindowDimensions();

  const toggleAdmMenu = () => {
    setToggleAdministrationMenu(!toggleAdministrationMenu);
  };

  return (
    <div
      className="menu bg-redPrimary absolute top-0 bottom-0 left-0 sm:relative z-[999] pt-7"
      style={{
        minWidth: width > 640 || openSideMenu ? "fit-content" : 0,
        // width: width > 640 || openSideMenu ? "fit-content" : 0,
        display: width <= 640 && !openSideMenu ? "none" : "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflowX: "hidden",
        overflowY: "scroll",
      }}
    >
      {toggleAdministrationMenu ? (
        <div
          className={`${
            openSideMenu && toggleAdministrationMenu
              ? "space-y-4"
              : openSideMenu && !toggleAdministrationMenu && "space-y-8 "
          }  `}
        >
          <div>
            {openSideMenu && (
              <div className="flex flex-col">
                <span
                  className="menuTitles pb-7 pt-2 flex items-center gap-4 cursor-pointer"
                  onClick={toggleAdmMenu}
                >
                  <img src={arrow_backward} alt="arrow_backward"></img>
                  <FormattedMessage id="administration_menu.menuTitle" />
                </span>

                <span className="menuTitles">
                  <FormattedMessage id="administration_menu.title" />
                </span>
              </div>
            )}

            <HorizontalLine
              openSideMenu={openSideMenu}
              marginVertical={"my-1"}
              paddingHorizontal={"px-5"}
            ></HorizontalLine>

            {AdministrationItems.map((item, index) => (
              <MenuBtn
                key={index}
                openSideMenu={openSideMenu}
                pathname={item.pathname}
                title={item.title}
                icon={item.icon}
                linkTo={item.linkTo}
                toggleAdministrationMenu={toggleAdministrationMenu}
              ></MenuBtn>
            ))}
          </div>

          <div>
            {openSideMenu && (
              <span className="menuTitles mt-10">
                <FormattedMessage id="administration_menu.title2" />
              </span>
            )}
            <HorizontalLine
              openSideMenu={openSideMenu}
              marginVertical={"my-1"}
              paddingHorizontal={"px-5"}
            ></HorizontalLine>

            {AdministrationItems2.map((item, index) => (
              <MenuBtn
                key={index}
                openSideMenu={openSideMenu}
                pathname={item.pathname}
                title={item.title}
                icon={item.icon}
                linkTo={item.linkTo}
                toggleAdministrationMenu={toggleAdministrationMenu}
              ></MenuBtn>
            ))}
          </div>

          <div>
            {openSideMenu && (
              <span className="menuTitles mt-10">
                <FormattedMessage id="administration_menu.title3" />
              </span>
            )}
            <HorizontalLine
              openSideMenu={openSideMenu}
              marginVertical={"my-1"}
              paddingHorizontal={"px-5"}
            ></HorizontalLine>

            {AdministrationItems3.map((item, index) => (
              <MenuBtn
                key={index}
                openSideMenu={openSideMenu}
                pathname={item.pathname}
                title={item.title}
                icon={item.icon}
                linkTo={item.linkTo}
                toggleAdmMenu={toggleAdmMenu}
                toggleAdministrationMenu={toggleAdministrationMenu}
              ></MenuBtn>
            ))}
          </div>

          <div>
            {openSideMenu && (
              <span className="menuTitles mt-10">
                <FormattedMessage id="administration_menu.title4" />
              </span>
            )}
            <HorizontalLine
              openSideMenu={openSideMenu}
              marginVertical={"my-1"}
              paddingHorizontal={"px-5"}
            ></HorizontalLine>

            {AdministrationItems4.map((item, index) => (
              <MenuBtn
                key={index}
                openSideMenu={openSideMenu}
                pathname={item.pathname}
                title={item.title}
                icon={item.icon}
                linkTo={item.linkTo}
                toggleAdministrationMenu={toggleAdministrationMenu}
              ></MenuBtn>
            ))}
          </div>
        </div>
      ) : (
        <div className={`${openSideMenu && "space-y-8"} `}>
          <div>
            {openSideMenu && (
              <span className="menuTitles">
                <FormattedMessage id="menu.title" />
              </span>
            )}

            <HorizontalLine
              openSideMenu={openSideMenu}
              marginVertical={"my-1"}
              paddingHorizontal={"px-5"}
            ></HorizontalLine>

            {MenuItems.map((item, index) => (
              <MenuBtn
                key={index}
                openSideMenu={openSideMenu}
                pathname={item.pathname}
                title={item.title}
                icon={item.icon}
                linkTo={item.linkTo}
              ></MenuBtn>
            ))}
          </div>

          <div>
            {openSideMenu && (
              <span className="menuTitles mt-10">
                <FormattedMessage id="menu.title2" />
              </span>
            )}
            <HorizontalLine
              openSideMenu={openSideMenu}
              marginVertical={"my-1"}
              paddingHorizontal={"px-5"}
            ></HorizontalLine>

            {MenuItems2.map((item, index) => (
              <MenuBtn
                key={index}
                openSideMenu={openSideMenu}
                pathname={item.pathname}
                title={item.title}
                icon={item.icon}
                linkTo={item.linkTo}
                icon2={item.icon2}
              ></MenuBtn>
            ))}
          </div>

          <div>
            {openSideMenu && (
              <span className="menuTitles mt-10">
                <FormattedMessage id="menu.title3" />
              </span>
            )}
            <HorizontalLine
              openSideMenu={openSideMenu}
              marginVertical={"my-1"}
              paddingHorizontal={"px-5"}
            ></HorizontalLine>

            {MenuItems3.map((item, index) => (
              <MenuBtn
                key={index}
                openSideMenu={openSideMenu}
                pathname={item.pathname}
                title={item.title}
                icon={item.icon}
                linkTo={item.linkTo}
                icon2={item.icon2}
                toggleAdmMenu={toggleAdmMenu}
              ></MenuBtn>
            ))}
          </div>
        </div>
      )}

      <div
        style={{
          paddingTop: "40px",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          paddingBottom: "10px",
        }}
      >
        <MenuBtn
          openSideMenu={openSideMenu}
          pathname={MenuItems4[0].pathname}
          title={MenuItems4[0].title}
          icon={MenuItems4[0].icon}
          linkTo={MenuItems4[0].linkTo}
          layoutWidth={width}
        ></MenuBtn>

        <div className={` ${openSideMenu && "px-5"}`}>
          <hr className=" h-[2px] bg-white opacity-20 "></hr>
        </div>

        <MenuBtn
          openSideMenu={openSideMenu}
          pathname={MenuItems4[1].pathname}
          title={MenuItems4[1].title}
          icon={MenuItems4[1].icon}
          linkTo={MenuItems4[1].linkTo}
          toggleLogout={toggleLogout}
        ></MenuBtn>
      </div>
    </div>
  );
};

Menu.propTypes = {
  openSideMenu: PropTypes.bool,
  toggleLogout: PropTypes.func,
};

export default Menu;
