import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import CreateEvent from "./components/CreateEvent";
import CalendarHeader from "./components/CalendarHeader";
import Sidebar from "./components/sidebar/Sidebar";
import Month from "./components/Month";

import { getMonth } from "../../utils/getMonth";

import {
  reset,
  setDaySelected,
  setSelectedType,
} from "../../store/calendar/calendarSlice";

const Maintenance = () => {
  const dispatch = useDispatch();

  const monthIndex = useSelector((state) => state.calendar.monthIndex);
  const modal = useSelector((state) => state.calendar.createEventModal);
  const [currentMonth, setCurrentMonth] = useState(getMonth());

  console.log("This is currentMonth:", currentMonth);

  useEffect(() => {
    setCurrentMonth(getMonth(monthIndex));
  }, [monthIndex]);

  useEffect(() => {
    return () => {
      dispatch(setDaySelected(null));

      dispatch(setSelectedType(null));

      dispatch(reset());
    };
  }, [dispatch]);

  return (
    <>
      {modal && <CreateEvent />}
      <div className="flex flex-col md:flex-row flex-1 overflow-scroll border-t h-full">
        <Sidebar />
        <div className="flex flex-col flex-1 h-full border-l-[1px] border-gray-200">
          <CalendarHeader />
          <Month month={currentMonth} />
        </div>
      </div>
    </>
  );
};

export default Maintenance;
