import React from "react";
import { PropTypes } from "prop-types";
import { FormattedMessage } from "react-intl";

const SearchInput = ({
  lngId,
  id,
  searchValue,
  handleChange,
  labelLngId,
  containerStyles,
  mainStyles,
  labelStyle,
  icon,
}) => {
  return (
    <div className={`flex flex-col ${containerStyles}`}>
      {labelLngId && (
        <span className={labelStyle}>
          <FormattedMessage id={labelLngId} />
        </span>
      )}

      <div
        className={`flex rounded-md overflow-hidden items-center ${mainStyles}`}
      >
        {icon && (
          <img
            src={icon}
            alt={icon}
            className="px-3 size-10 cursor-pointer"
          ></img>
        )}

        <FormattedMessage id={lngId} defaultMessage="search">
          {(placeholder) => (
            <input
              type="text"
              value={searchValue}
              onChange={handleChange}
              id={id}
              placeholder={placeholder}
              className="outline-none flex-1 h-full"
              // style={{ paddingLeft: !icon && 10 }}
            />
          )}
        </FormattedMessage>
      </div>
    </div>
  );
};

SearchInput.propTypes = {
  lngId: PropTypes.string,
  id: PropTypes.string,
  searchValue: PropTypes.string,
  handleChange: PropTypes.func,
  labelLngId: PropTypes.string,
  labelStyle: PropTypes.string,
  icon: PropTypes.string,
  containerStyles: PropTypes.string,
  mainStyles: PropTypes.string,
};

export default SearchInput;
