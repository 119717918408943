/* eslint-disable react/prop-types */
import React, { useEffect, useState, useCallback } from "react";
import { PropTypes } from "prop-types";
import { useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import { FormattedMessage } from "react-intl";
import { useWindowDimensions } from "../../../../utils/GetDimensions";
import searchIcon from "../../../../assets/icons/Panel/searchIcon.svg";

// This is a custom built autocomplete component using the "Autocomplete Service" for predictions
// and the "Places Service" for place details
const Autocomplete = ({ onPlaceSelect, filterWidth, place }) => {
  const map = useMap();
  const places = useMapsLibrary("places");
  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompleteSessionToken
  const [sessionToken, setSessionToken] = useState();
  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service
  const [autocompleteService, setAutocompleteService] = useState(null);
  // https://developers.google.com/maps/documentation/javascript/reference/places-service
  const [placesService, setPlacesService] = useState(null);
  const [predictionResults, setPredictionResults] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (!places || !map) return;

    setAutocompleteService(new places.AutocompleteService());

    setPlacesService(new places.PlacesService(map));

    setSessionToken(new places.AutocompleteSessionToken());

    // eslint-disable-next-line consistent-return
    return () => setAutocompleteService(null);
  }, [map, places]);

  const fetchPredictions = useCallback(
    // eslint-disable-next-line no-shadow
    async (inputValue) => {
      if (!autocompleteService || !inputValue) {
        setPredictionResults([]);

        return;
      }

      const request = { input: inputValue, sessionToken };
      const response = await autocompleteService.getPlacePredictions(request);

      setPredictionResults(response.predictions);
    },
    [autocompleteService, sessionToken]
  );

  const onInputChange = useCallback(
    (event) => {
      const value = event.target?.value;

      setInputValue(value);

      fetchPredictions(value);
    },
    [fetchPredictions]
  );

  const handleSuggestionClick = useCallback(
    (placeId) => {
      if (!places) return;

      const detailRequestOptions = {
        placeId,
        fields: ["geometry", "name", "formatted_address"],
        sessionToken,
      };

      const detailsRequestCallback = (placeDetails) => {
        onPlaceSelect(placeDetails);

        setPredictionResults([]);

        setInputValue(placeDetails?.formatted_address ?? "");

        setSessionToken(new places.AutocompleteSessionToken());
      };

      placesService?.getDetails(detailRequestOptions, detailsRequestCallback);
    },
    [onPlaceSelect, places, placesService, sessionToken]
  );

  const handleSearchIconClick = () => {
    if (place.geometry?.viewport) {
      // map.fitBounds(place.geometry?.viewport);
      map.panTo(place.geometry?.location);

      setTimeout(() => {
        map.setZoom(15);
      }, [1000]);
    }
  };

  return (
    <div
      className={`absolute top-10 w-full h-5 xs:max-w-[320px]`}
      style={{
        left: filterWidth + 78,
        transition: "left",
        transitionDelay: ".2s",
        transitionTimingFunction: "ease-in-out",
        transitionDuration: ".3s",
        maxWidth: width < 640 ? width - (filterWidth + 88) : 320,
      }}
    >
      <div className=" h-[40px] w-full rounded-full shadow-md flex items-center bg-white overflow-hidden">
        <img
          src={searchIcon}
          className="pl-3 active:opacity-70 cursor-pointer"
          onClick={handleSearchIconClick}
        ></img>

        <FormattedMessage id={"customControl.placeholder"}>
          {(placeholder) => (
            <input
              className="h-full flex-1 text-small text-blackPrimary pl-2 outline-none truncate"
              placeholder={placeholder}
              value={inputValue}
              onInput={(event) => onInputChange(event)}
            />
          )}
        </FormattedMessage>
      </div>

      {predictionResults.length > 0 && (
        <ul className="custom-list">
          {predictionResults.map(({ place_id, description }) => {
            return (
              <li
                key={place_id}
                className="custom-list-item"
                onClick={() => handleSuggestionClick(place_id)}
              >
                {description}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

Autocomplete.propTypes = {
  onPlaceSelect: PropTypes.func,
  filterWidth: PropTypes.number,
  place: PropTypes.Object,
};

export default Autocomplete;
