import React from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";

import eyeIcon from "../../../../assets/icons/Panel/administration/DotWindow/eyeIcon.svg";
import editIcon from "../../../../assets/icons/Panel/administration/DotWindow/editIcon.svg";
import deleteIcon from "../../../../assets/icons/Panel/administration/DotWindow/deleteIcon.svg";
import addToGroupIcon from "../../../../assets/icons/Panel/administration/DotWindow/addToGroupIcon.svg";
import moveToRoleIcon from "../../../../assets/icons/Panel/administration/DotWindow/moveToRoleIcon.svg";
import assignAssetsIcon from "../../../../assets/icons/Panel/administration/DotWindow/assignAssetsIcon.svg";
import resetPasswordIcon from "../../../../assets/icons/Panel/administration/DotWindow/resetPasswordIcon.svg";
import locationIcon from "../../../../assets/icons/Panel/administration/DotWindow/locationIcon.svg";

// eslint-disable-next-line no-unused-vars
const DotsWindow = ({ setState, setRowData, data, version, top, left }) => {
  const handleClick = (value) => {
    if (value === "view") {
      setRowData(data);

      setState(value);
    }
  };

  return (
    <div className="dotsWindow-container" style={{ top, left }}>
      {version === 5 && (
        <div onClick={() => handleClick("assetPage")}>
          <span>
            <img src={eyeIcon}></img>
          </span>
          <span>
            <FormattedMessage id="tableDotsWindow.text8" />
          </span>
        </div>
      )}
      {version === 5 && (
        <div onClick={() => handleClick("assetPage")}>
          <span>
            <img src={locationIcon}></img>
          </span>
          <span>
            <FormattedMessage id="tableDotsWindow.text9" />
          </span>
        </div>
      )}
      {version !== 3 && version !== 5 && version !== 6 && (
        <div onClick={() => handleClick("view")}>
          <span>
            <img src={eyeIcon}></img>
          </span>
          <span>
            <FormattedMessage id="tableDotsWindow.text1" />
          </span>
        </div>
      )}
      {version !== 3 && version !== 5 && (
        <div onClick={() => handleClick("edit")}>
          <span>
            <img src={editIcon}></img>
          </span>
          <span>
            <FormattedMessage id="tableDotsWindow.text2" />
          </span>
        </div>
      )}
      {(version === 2 || version === 3) && version !== 5 && (
        <div onClick={() => handleClick("addToGroup")}>
          <span>
            <img src={addToGroupIcon}></img>
          </span>
          <span>
            <FormattedMessage id="tableDotsWindow.text3" />
          </span>
        </div>
      )}
      {(version === 2 || version === 3) && version !== 5 && (
        <div onClick={() => handleClick("moveToRole")}>
          <span>
            <img src={moveToRoleIcon}></img>
          </span>
          <span>
            <FormattedMessage id="tableDotsWindow.text4" />
          </span>
        </div>
      )}
      {version === 2 && version !== 5 && (
        <div onClick={() => handleClick("assignAssets")}>
          <span>
            <img src={assignAssetsIcon}></img>
          </span>
          <span>
            <FormattedMessage id="tableDotsWindow.text5" />
          </span>
        </div>
      )}
      {version === 2 && version !== 5 && (
        <div onClick={() => handleClick("resetPassword")}>
          <span>
            <img src={resetPasswordIcon}></img>
          </span>
          <span>
            <FormattedMessage id="tableDotsWindow.text6" />
          </span>
        </div>
      )}
      {version !== 5 && (
        <div onClick={() => handleClick("delete")}>
          <span>
            <img src={deleteIcon}></img>
          </span>
          <span>
            <FormattedMessage id="tableDotsWindow.text7" />
          </span>
        </div>
      )}
    </div>
  );
};

DotsWindow.propTypes = {
  setState: PropTypes.Func,
  setRowData: PropTypes.Func,
  data: PropTypes.object,
  version: PropTypes.number,
  top: PropTypes.number,
  left: PropTypes.number,
};

export default DotsWindow;
