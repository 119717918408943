import React from "react";
import { PropTypes } from "prop-types";
import { FormattedMessage } from "react-intl";
import logoutIcon from "../../assets/icons/Panel/Logout/logoutIconsvg.svg";
import closeIcon from "../../assets/icons/Panel/Logout/close-icon.svg";
import ReusableBtn from "../ReusableBtn/ReusableBtn";

const Logout = ({ toggleLogout }) => {
  return (
    <div className="logout-container">
      <div className="logout-popup">
        <div className="logout-header">
          <h1 className=" flex-1 text-center">Log out</h1>
          <img
            src={closeIcon}
            className=" size-5 cursor-pointer opacity-70 active:opacity-50"
            onClick={toggleLogout}
          ></img>
        </div>

        <div className="logout-body">
          <img src={logoutIcon}></img>
          <p className=" text-[#333333]">
            <FormattedMessage id="logout.text2" />
          </p>
        </div>

        <div className="logout-footer">
          <ReusableBtn
            mainStyles={
              "w-full max-w-[362px] h-[50px] bg-transparent gap-2 border border-[#707070] rounded-md"
            }
            textStyles={"text-btnTextSize text-[#333333]"}
            text={"logout.btn1"}
            handleClick={toggleLogout}
          />

          <ReusableBtn
            mainStyles={
              "w-full max-w-[362px] h-[50px] bg-redPrimary gap-2 rounded-md"
            }
            textStyles={"text-btnTextSize text-[#FFFFFF]"}
            text={"logout.btn2"}
            handleClick={toggleLogout}
          />
        </div>
      </div>
    </div>
  );
};

Logout.propTypes = {
  toggleLogout: PropTypes.func,
};

export default Logout;
