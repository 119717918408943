import React, { useEffect } from "react";
import { PropTypes } from "prop-types";

import InputWithLabel from "../../../../components/Inputs/InputWithLabel";

import { FormattedMessage } from "react-intl";

const CreateUserGroup = ({ formData, setFormData }) => {
  useEffect(() => {
    return () => {
      setFormData({
        name: "",
        shortName: "",
        description: "",
      });
    };
  }, []);

  const handleChange = (event) => {
    const { name } = event.target;

    setFormData((prevValues) => ({
      ...prevValues,
      [name]: event.target.value,
    }));
  };

  return (
    <form id="createAssetForm" className="formContainer">
      <div className="groupContainer">
        <h1 className=" font-bold text-inputsGroupTitle text-pageTitle">
          <FormattedMessage id="userGroup.userDetailsText" />
        </h1>
        <InputWithLabel
          id={"name"}
          name={"name"}
          labelLngId={"userGroup.createField1Label"}
          labelStyles={"text-extraSmall text-textSecondary"}
          handleChange={handleChange}
          value={formData.name}
        />
        <InputWithLabel
          id={"shortName"}
          name={"shortName"}
          labelLngId={"userGroup.createField2Label"}
          labelStyles={"text-extraSmall text-textSecondary"}
          handleChange={handleChange}
          value={formData.shortName}
        />

        <InputWithLabel
          id={"description"}
          name={"description"}
          labelLngId={"userGroup.createField3Label"}
          labelStyles={"text-extraSmall text-textSecondary"}
          handleChange={handleChange}
          value={formData.description}
          version={"textarea"}
        />
      </div>
    </form>
  );
};

CreateUserGroup.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
};

export default CreateUserGroup;
