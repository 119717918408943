import React from "react";
import { PropTypes } from "prop-types";
import { FormattedMessage } from "react-intl";

const InputWithLabel = ({
  inputId,
  name,
  labelLngId,
  mainStyles,
  labelStyles,
  handleChange,
  value,
  version,
  placeholderLngId,
}) => {
  return (
    <div
      className={`w-full bg-white rounded-md h-[50px] flex flex-col py-1 px-3 ${mainStyles}`}
      style={{ height: version !== "textarea" ? "50px" : "fit-content" }}
    >
      <label htmlFor={inputId} className={labelStyles}>
        <FormattedMessage id={labelLngId} />
      </label>
      {version === "textarea" ? (
        <textarea
          id={inputId}
          name={name}
          type="text"
          className=" outline-none"
          value={value}
          onChange={handleChange}
          rows={5}
        ></textarea>
      ) : (
        <FormattedMessage
          id={placeholderLngId ? placeholderLngId : "app.emptyLabel"}
        >
          {(placeholder) => (
            <input
              id={inputId}
              name={name}
              type="text"
              placeholder={placeholder}
              className=" outline-none truncate placeholder:text-sm"
              value={value}
              onChange={handleChange}
            />
          )}
        </FormattedMessage>
      )}
    </div>
  );
};

InputWithLabel.propTypes = {
  inputId: PropTypes.string,
  name: PropTypes.string,
  labelLngId: PropTypes.string,
  handleChange: PropTypes.func,
  value: PropTypes.string,
  labelStyles: PropTypes.string,
  mainStyles: PropTypes.string,
  placeholderLngId: PropTypes.string,
  version: PropTypes.number,
};

export default InputWithLabel;
