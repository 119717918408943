import React, { useEffect } from "react";
import PropTypes from "prop-types";

import Day from "./Day";
import { useDispatch } from "react-redux";
import { setAllEvents } from "../../../store/calendar/calendarSlice.js";

const Month = ({ month }) => {
  console.log("This is month:", month);

  const dispatch = useDispatch();

  useEffect(() => {
    const getAllEvents = () => {
      try {
        const storedEvents = localStorage.getItem("allEvents");

        if (storedEvents) {
          dispatch(setAllEvents(JSON.parse(storedEvents)));
        } else {
          dispatch(setAllEvents([]));
        }
      } catch (error) {
        console.error(error);
      }
    };

    getAllEvents();
  }, [dispatch]);

  return (
    <div className="flex-1 grid grid-cols-7 justify-center items-center grid-row-5 h-full">
      {month.map((row, index) => {
        return (
          <React.Fragment key={index}>
            {row.map((day, i) => (
              <Day
                day={day}
                key={i}
                keyIndex={i}
                rowIndex={index}
                colIndex={i}
              />
            ))}
          </React.Fragment>
        );
      })}
    </div>
  );
};

Month.propTypes = {
  month: PropTypes.number,
};

export default Month;
