import React from "react";
import { PropTypes } from "prop-types";
import ViewAsset from "../../Components/ViewAsset/ViewAsset";
import {
  PoisGroupDetailTitles,
  PoiGroupDetailsTable,
} from "../../../../constants";
import { FormattedMessage } from "react-intl";
import Table from "../../Components/table/Table";

const bodyData = [
  {
    id: 0,
    data: [
      {
        id: 0,
        row: "Adc DataCenter HUB 10",
      },
    ],
  },

  {
    id: 1,
    data: [
      {
        id: 0,
        row: "DR NOD 06-02",
      },
    ],
  },
];

const ViewPoisGroup = ({ data }) => {
  return (
    <div className="space-y-4">
      <ViewAsset
        titleLngId={"poisGroup.detailsTitle1"}
        titles={PoisGroupDetailTitles}
        data={data}
      />

      <div>
        <h3 className=" font-bold text-[20px]">
          <FormattedMessage id={"poisGroup.detailsTitle1"} />
        </h3>

        <Table headerData={PoiGroupDetailsTable} bodyData={bodyData} />
      </div>
    </div>
  );
};

ViewPoisGroup.propTypes = {
  data: PropTypes.object,
};

export default ViewPoisGroup;
