import React, { useEffect } from "react";

import { PropTypes } from "prop-types";

import { FormattedMessage } from "react-intl";

import { options3 } from "../../../../constants";

import SelectInput from "../../../../components/Inputs/SelectInput";
import InputWithLabel from "../../../../components/Inputs/InputWithLabel";

const CreateNewDevice = ({ formData, setFormData }) => {
  useEffect(() => {
    return () => {
      setFormData({
        deviceSerial: "",
        deviceIMEI: "",
        deviceIMSI: "",
        deviceICCID: "",
        deviceMSISDN: "",
        deviceType: "",
      });
    };
  }, []);

  const handleChange = (event) => {
    const { name } = event.target;

    setFormData((prevValues) => ({
      ...prevValues,
      [name]: event.target.value,
    }));
  };

  return (
    <form id="createAssetForm" className="formContainer">
      <div className="groupContainer">
        <h1 className=" font-bold text-inputsGroupTitle text-pageTitle">
          <FormattedMessage id="devices.devicesDetailsText" />
        </h1>
        <InputWithLabel
          id={"deviceSerial"}
          name={"deviceSerial"}
          labelLngId={"devices.inputLabel1"}
          labelStyles={"text-extraSmall text-textSecondary"}
          handleChange={handleChange}
          value={formData.assetName}
        />
        <InputWithLabel
          id={"deviceIMEI"}
          name={"deviceIMEI"}
          labelLngId={"IMEI"}
          labelStyles={"text-extraSmall text-textSecondary"}
          handleChange={handleChange}
          value={formData.serialNumber}
        />
        <InputWithLabel
          id={"deviceIMSI"}
          name={"deviceIMSI"}
          labelLngId={"IMSI"}
          labelStyles={"text-extraSmall text-textSecondary"}
          handleChange={handleChange}
          value={formData.serialNumber}
        />
        <InputWithLabel
          id={"deviceICCID"}
          name={"deviceICCID"}
          labelLngId={"ICCID"}
          labelStyles={"text-extraSmall text-textSecondary"}
          handleChange={handleChange}
          value={formData.serialNumber}
        />
        <InputWithLabel
          id={"deviceMSISDN"}
          name={"deviceMSISDN"}
          labelLngId={"MSISDN"}
          labelStyles={"text-extraSmall text-textSecondary"}
          handleChange={handleChange}
          value={formData.serialNumber}
        />

        <SelectInput
          id={"deviceType"}
          name={"deviceType"}
          className={
            "w-full bg-white rounded-md h-[55px] flex flex-col py-1 px-2 text-createAssetInputText text-blackPrimary"
          }
          options={options3}
          labelLngId={"devices.inputLabel2"}
          labelStyles={"text-extraSmall text-textSecondary ml-1"}
          onChange={handleChange}
        />
      </div>
    </form>
  );
};

CreateNewDevice.propTypes = {
  formData: PropTypes.Object,
  setFormData: PropTypes.Func,
};

export default CreateNewDevice;
