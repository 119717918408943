import React, { useEffect } from "react";
import { PropTypes } from "prop-types";

import SelectInput from "../../../../components/Inputs/SelectInput";
import Checkbox from "../../../../components/Inputs/Checkbox";
import InputWithLabel from "../../../../components/Inputs/InputWithLabel";

import { FormattedMessage } from "react-intl";
import { options3 } from "../../../../constants";

const CreateUser = ({ formData, setFormData }) => {
  useEffect(() => {
    return () => {
      setFormData({
        firstName: "",
        lastName: "",
        fixedPhone: "",
        role: "",
        userGroups: "",
        commandGroups: "",
        authorizedMobileApps: "",
        userTypes: "",
        email: "",
        timezone: "",
        language: "",
        country: "",
        isDriver: false,
        driverTag: "",
        sendEmail: false,
      });
    };
  }, []);

  const handleChange = (event) => {
    const { name } = event.target;

    if (name === "isDriver" || name === "sendEmail") {
      setFormData((prevValues) => ({
        ...prevValues,
        [name]: !formData[name],
        //   event.target.checked || event.target.checked === "true"
        //     ? true
        //     : false,
      }));
    } else {
      setFormData((prevValues) => ({
        ...prevValues,
        [name]: event.target.value,
      }));
    }
  };

  return (
    <form id="createAssetForm" className="formContainer">
      <div className="groupContainer">
        <h1 className=" font-bold text-inputsGroupTitle text-pageTitle">
          <FormattedMessage id="users.userDetailsText" />
        </h1>
        <InputWithLabel
          id={"firstName"}
          name={"firstName"}
          labelLngId={"users.detailsField1"}
          labelStyles={"text-extraSmall text-textSecondary"}
          handleChange={handleChange}
          value={formData.firstName}
        />
        <InputWithLabel
          id={"lastName"}
          name={"lastName"}
          labelLngId={"users.detailsField2"}
          labelStyles={"text-extraSmall text-textSecondary"}
          handleChange={handleChange}
          value={formData.lastName}
        />
        <InputWithLabel
          id={"fixedPhone"}
          name={"fixedPhone"}
          labelLngId={"users.detailsField3"}
          labelStyles={"text-extraSmall text-textSecondary"}
          handleChange={handleChange}
          value={formData.fixedPhone}
        />
        <InputWithLabel
          id={"mobilePhone"}
          name={"mobilePhone"}
          labelLngId={"users.detailsField4"}
          labelStyles={"text-extraSmall text-textSecondary"}
          handleChange={handleChange}
          value={formData.mobilePhone}
        />
        <SelectInput
          id={"role"}
          name={"role"}
          className={
            "w-full bg-white rounded-md h-[55px] flex flex-col py-1 px-2 text-createAssetInputText text-blackPrimary"
          }
          options={options3}
          labelLngId={"devices.inputLabel2"}
          labelStyles={"text-extraSmall text-textSecondary ml-1"}
          onChange={handleChange}
        />
        <SelectInput
          id={"userGroups"}
          name={"userGroups"}
          className={
            "w-full bg-white rounded-md h-[55px] flex flex-col py-1 px-2 text-createAssetInputText text-blackPrimary"
          }
          options={options3}
          labelLngId={"users.detailsField6"}
          labelStyles={"text-extraSmall text-textSecondary ml-1"}
          onChange={handleChange}
        />
        <SelectInput
          id={"commandGroups"}
          name={"commandGroups"}
          className={
            "w-full bg-white rounded-md h-[55px] flex flex-col py-1 px-2 text-createAssetInputText text-blackPrimary"
          }
          options={options3}
          labelLngId={"users.detailsField7"}
          labelStyles={"text-extraSmall text-textSecondary ml-1"}
          onChange={handleChange}
        />
        <SelectInput
          id={"authorizedMobileApps"}
          name={"authorizedMobileApps"}
          className={
            "w-full bg-white rounded-md h-[55px] flex flex-col py-1 px-2 text-createAssetInputText text-blackPrimary"
          }
          options={options3}
          labelLngId={"users.createField8"}
          labelStyles={"text-extraSmall text-textSecondary ml-1"}
          onChange={handleChange}
        />
        <SelectInput
          id={"userTypes"}
          name={"userTypes"}
          className={
            "w-full bg-white rounded-md h-[55px] flex flex-col py-1 px-2 text-createAssetInputText text-blackPrimary"
          }
          options={options3}
          labelLngId={"users.createField9"}
          labelStyles={"text-extraSmall text-textSecondary ml-1"}
          onChange={handleChange}
        />
      </div>
      <div className="groupContainer">
        <h1 className=" font-bold text-inputsGroupTitle text-pageTitle">
          <FormattedMessage id="users.userDetailsText2" />
        </h1>
        <InputWithLabel
          id={"email"}
          name={"email"}
          labelLngId={"users.detailsField9"}
          labelStyles={"text-extraSmall text-textSecondary"}
          handleChange={handleChange}
          value={formData.email}
        />
      </div>
      <div className="groupContainer">
        <h1 className=" font-bold text-inputsGroupTitle text-pageTitle">
          <FormattedMessage id="users.userDetailsText3" />
        </h1>
        <SelectInput
          id={"timezone"}
          name={"timezone"}
          className={
            "w-full bg-white rounded-md h-[55px] flex flex-col py-1 px-2 text-createAssetInputText text-blackPrimary"
          }
          options={options3}
          labelLngId={"users.detailsField11"}
          labelStyles={"text-extraSmall text-textSecondary ml-1"}
          onChange={handleChange}
        />
        <SelectInput
          id={"language"}
          name={"language"}
          className={
            "w-full bg-white rounded-md h-[55px] flex flex-col py-1 px-2 text-createAssetInputText text-blackPrimary"
          }
          options={options3}
          labelLngId={"users.detailsField12"}
          labelStyles={"text-extraSmall text-textSecondary ml-1"}
          onChange={handleChange}
        />
        <SelectInput
          id={"country"}
          name={"country"}
          className={
            "w-full bg-white rounded-md h-[55px] flex flex-col py-1 px-2 text-createAssetInputText text-blackPrimary"
          }
          options={options3}
          labelLngId={"users.detailsField13"}
          labelStyles={"text-extraSmall text-textSecondary ml-1"}
          onChange={handleChange}
        />
      </div>
      <div className="groupContainer">
        <h1 className=" font-bold text-inputsGroupTitle text-pageTitle">
          <FormattedMessage id="users.userDetailsText4" />
        </h1>
        <Checkbox
          labelLngId={"users.createField14Label"}
          labelSize={"text-text16"}
          labelColor={"text-textSecondary"}
          name={"isDriver"}
          checked={formData.isDriver === true}
          handleChange={handleChange}
        />
        <SelectInput
          id={"driverTag"}
          name={"driverTag"}
          className={
            "w-full bg-white rounded-md h-[55px] flex flex-col py-1 px-2 text-createAssetInputText text-blackPrimary"
          }
          options={options3}
          labelLngId={"users.createField15Label"}
          labelStyles={"text-extraSmall text-textSecondary ml-1"}
          onChange={handleChange}
        />
        <Checkbox
          labelLngId={"users.createField16Label"}
          labelSize={"text-text16"}
          labelColor={"text-textSecondary"}
          name={"sendEmail"}
          checked={formData.sendEmail === true}
          handleChange={handleChange}
        />
      </div>
    </form>
  );
};

CreateUser.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
};

export default CreateUser;
