/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import SelectInput from "../../../../components/Inputs/SelectInput";
import InputWithLabel from "../../../../components/Inputs/InputWithLabel";
import ReusableBtn from "../../../../components/ReusableBtn/ReusableBtn";
import Upload from "./Upload";
import { bulkUploadsOptions } from "../../../../constants";
import { FormattedMessage } from "react-intl";
import downloadIcon from "../../../../assets/icons/Panel/administration/BulkUploads/downloadIcon.svg";

const AddBulkUpload = ({ handleBtnActions }) => {
  const [formData, setFormData] = useState({
    batchType: "",
    batchName: "",
    excel: null,
  });

  const [isUploading, setIsUploading] = useState(false);

  const handleChange = (event) => {
    const { id } = event.target;

    if (id === "excel") {
      setIsUploading(true);

      setFormData((prev) => ({ ...prev, [id]: event.target.files[0] }));
    } else {
      setFormData((prev) => ({ ...prev, [id]: event.target.value }));
    }
  };

  const handleUpload = (event) => {
    event.preventDefault();

    console.log("This is formData:", formData);
  };

  useEffect(() => {
    if (formData.excel) {
      setIsUploading(false);
    }
  }, [formData.excel]);

  return (
    <form
      onSubmit={handleUpload}
      className="flex flex-col justify-between h-[calc(100vh-180px)]"
    >
      <div className="space-y-5">
        <SelectInput
          id={"batchType"}
          name={"batchType"}
          className={
            "w-full h-[50px] xl:max-w-[50%] bg-white border rounded-md px-2 text-gray-300 outline-none"
          }
          options={bulkUploadsOptions}
          onChange={handleChange}
          labelLngId={"bulkUploads.selectInputTitle"}
          labelStyles={"text-extraSmall text-textSecondary ml-1"}
        />

        <InputWithLabel
          inputId={"batchName"}
          name={"batchName"}
          labelLngId={"bulkUploads.textInputTitle"}
          mainStyles={"border rounded-md bg-white h-[50px] xl:max-w-[50%]"}
          labelStyles={"text-extraSmall text-textSecondary"}
          handleChange={handleChange}
          value={formData.batchName}
          placeholderLngId={"bulkUploads.textInputPlaceholder"}
        />

        <div className="flex items-center gap-5 sm:gap-10 p-5">
          <div>
            <h4 className="text-extraSmall text-textSecondary">
              <FormattedMessage id="bulkUploads.text1" />
            </h4>
            <p className="text-text16">
              <FormattedMessage id="bulkUploads.text2" />
            </p>
          </div>

          <ReusableBtn
            mainStyles={"w-[135px] h-[50px] bg-[#007C92] gap-2 px-4 rounded-md"}
            textStyles={"text-text16 text-white"}
            icon={downloadIcon}
            text={"bulkUploads.btn3"}
            handleClick
          />
        </div>

        <Upload
          title={"bulkUploads.text3"}
          mainStyles={"h-[120px] xl:max-w-[50%]"}
          handleChange={handleChange}
          isUploading={isUploading}
          fileName={formData.excel && formData.excel.name}
        />
      </div>

      <div className="flex items-center p-4 border-t border-dashed justify-center md:justify-end mt-5 gap-5 fixed bottom-0 right-0 left-0">
        <ReusableBtn
          id={"table"}
          mainStyles={
            "w-[106px] h-[45px] bg-white rounded-md border-2 border-blackPrimary"
          }
          textStyles={"text-text18"}
          text={"bulkUploads.discard"}
          handleClick={handleBtnActions}
        />

        <ReusableBtn
          id={"upload"}
          type={"submit"}
          imgWidth={"14px"}
          mainStyles={"w-[106px] h-[45px] bg-redPrimary rounded-md"}
          textStyles={"text-text18 text-[#FFFFFF]"}
          text={"bulkUploads.upload"}
          disabled={isUploading}
        />
      </div>
    </form>
  );
};

AddBulkUpload.propTypes = {
  handleBtnActions: PropTypes.func,
};

export default AddBulkUpload;
