/* eslint-disable indent */
/* eslint-disable no-undef */
/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { RaportTypes, GenerateRaportTable } from "../../../constants";
import OverviewComponent from "../../Administration/Notifications/Components/OverviewComponent";
import SearchInput from "../../../components/Inputs/SearchInput";
import RaportElement from "./RaportElement";
import TextInput from "../../../components/Inputs/TextInput";
import Toggle from "../../../components/Toggle/Toggle";
import RaportGroup from "./RaportGroup";
import Table from "../../Administration/Components/table/Table";
import SelectedUserField from "./SelectedUserField";
import DateInput from "../../../components/Inputs/DateInput";
import rightArrowIcon from "../../../assets/icons/Panel/Raports/rightArrowIcon.svg";
import leftArrowIcon from "../../../assets/icons/Panel/Raports/leftArrowIcon.svg";
import searchIcon from "../../../assets/icons/Panel/searchIcon.svg";
import ReusableBtn from "../../../components/ReusableBtn/ReusableBtn";

const bodyData = [
  {
    id: 0,
    data: [
      {
        id: 0,
        row: "Ada Nakuci",
      },
      {
        id: 1,
        row: "ada.nakuci@adc.al",
      },
    ],
  },
  {
    id: 1,
    data: [
      {
        id: 0,
        row: "Daku Lu",
      },
      {
        id: 1,
        row: "daku.lu@adc.al",
      },
    ],
  },
];

const CreateNewRaport = ({ setView }) => {
  const [formData, setFormData] = useState({
    raportType: "all",
    raportName: "",
    users: "default",
    selectedUsers: [],
    fromDate: "",
    toDate: "",
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const [isChecked, setIsChecked] = useState({
    all: false,
  });
  const [raportGroupIndex, setRaportGroupIndex] = useState(0);
  const [raportElementIndex, setRaportElementIndex] = useState(null);
  const [descriptionLngId, setDescriptionLngId] = useState(
    "raports.createGroup1textareaPlaceholder"
  );
  const [searchValue, setSearchValue] = useState("");
  const [searchValue2, setSearchValue2] = useState("");

  useEffect(() => {
    setFormData((prevValues) => ({
      ...prevValues,
      selectedUsers: selectedRows,
    }));
  }, [selectedRows]);

  //Get all messages from lng file
  const allMessages = useIntl().messages;

  //Get all used lngId for our report element
  let raportsKeys = RaportTypes.map((el) => el.id);

  //Reconstruct the array with id and text
  let raportMessages = raportsKeys.map((el) => ({
    id: el,
    text: allMessages[el],
  }));

  //Reconstruct the filtered array
  let filteredArray = raportMessages.filter((el) =>
    el.text.toLowerCase().includes(searchValue.toLowerCase())
  );

  let filteredUserArray = bodyData.filter((el) =>
    el.data[0].row.toLowerCase().includes(searchValue2.toLowerCase())
  );

  const handleBtnClick = (event) => {
    const { id } = event.target;

    if (id === "next") {
      setRaportGroupIndex((prev) => prev + 1);
    }

    if (id === "back") {
      setRaportGroupIndex((prev) => prev - 1);
    }

    if (id === "table") {
      setView(id);
    }
  };

  const handleChange = (event) => {
    const { id } = event.target;

    if (id === "fromDate" || id === "toDate") {
      setFormData((prev) => ({ ...prev, [id]: event.target.value }));
    }

    if (id === "all" || id === "fleet" || id === "generic") {
      setFormData((prev) => ({ ...prev, raportType: id }));
    }

    if (id === "searchInput1") {
      setSearchValue(event.target.value);
    }

    if (id === "searchInput2") {
      setSearchValue2(event.target.value);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    console.log("this is formData:", formData);
  };

  useEffect(() => {
    return () => {
      setFormData({
        raportType: "all",
        raportName: "",
        users: "default",
        selectedUsers: [],
        fromDate: "",
        toDate: "",
      });
    };
  }, []);

  return (
    <form className="formContainer" onSubmit={handleSubmit}>
      <>
        <RaportGroup
          raportGroupIndex={raportGroupIndex}
          index={0}
          contentTitleLngId={"raports.createNewRaportTitle1"}
          readyToGo={raportElementIndex !== null && raportGroupIndex !== null}
        >
          <div className="flex flex-col xl:flex-row xl:justify-between gap-10">
            <div className="raportsBtnContainer ">
              <ReusableBtn
                id={"all"}
                text={"raports.createGroup1type1"}
                mainStyles={`w-fit h-[34px] ${
                  formData.raportType === "all"
                    ? "bg-redPrimary"
                    : "bg-[#EAF1F4]"
                } px-4 sm:px-5 rounded-[15px]`}
                textStyles={`${
                  formData.raportType === "all"
                    ? "text-white"
                    : "text-[#252931]"
                } text-extraSmall xs:text-regularSize`}
                handleClick={handleChange}
              />

              <ReusableBtn
                id={"fleet"}
                text={"raports.createGroup1type2"}
                mainStyles={`w-fit h-[34px] ${
                  formData.raportType === "fleet"
                    ? "bg-redPrimary"
                    : "bg-[#EAF1F4]"
                } px-4 sm:px-5 rounded-[15px]`}
                textStyles={`${
                  formData.raportType === "fleet"
                    ? "text-white"
                    : "text-[#252931]"
                } text-extraSmall xs:text-regularSize leading-3`}
                handleClick={handleChange}
              />

              <ReusableBtn
                id={"generic"}
                text={"raports.createGroup1type3"}
                mainStyles={`w-fit h-[34px] ${
                  formData.raportType === "generic"
                    ? "bg-redPrimary"
                    : "bg-[#EAF1F4]"
                } px-4 sm:px-5 rounded-[15px]`}
                textStyles={`${
                  formData.raportType === "generic"
                    ? "text-white"
                    : "text-[#252931]"
                } text-extraSmall xs:text-regularSize leading-3`}
                handleClick={handleChange}
              />
            </div>

            <div className="search-elements-container">
              <SearchInput
                lngId={"raports.createGroup1SearchText"}
                id={"searchInput1"}
                searchValue={searchValue}
                handleChange={handleChange}
                icon={searchIcon}
                mainStyles={
                  "border-[1px] border-[#EAF1F4] text-text16 text-[#606A70] h-[50px]"
                }
              />

              <div className=" flex flex-wrap gap-1 lg:gap-3">
                {filteredArray?.map((item) => (
                  <RaportElement
                    key={item.id}
                    id={item.id}
                    text={item.text}
                    setFormData={setFormData}
                    raportElementIndex={raportElementIndex}
                    setRaportElementIndex={setRaportElementIndex}
                    setDescriptionLngId={setDescriptionLngId}
                  ></RaportElement>
                ))}
              </div>
            </div>
          </div>

          <div className="next-btn">
            <ReusableBtn
              id={"next"}
              version={2}
              icon={rightArrowIcon}
              text={"raports.nextBtn"}
              mainStyles={
                "w-[130px] h-[45px] bg-redPrimary gap-2 rounded-[30px]"
              }
              textStyles={"text-text16 text-[#F8F8F8]"}
              // handleClick={handleNextBtnClick}
              // handleClick={handleNextPrevBtn}
              handleClick={handleBtnClick}
            />
          </div>
        </RaportGroup>

        <RaportGroup
          raportGroupIndex={raportGroupIndex}
          index={1}
          contentTitleLngId={"raports.createNewRaportTitle2"}
          readyToGo={
            formData.selectedUsers.length > 0 &&
            formData.fromDate &&
            formData.toDate
          }
        >
          <div className="space-y-5">
            <Toggle
              title={"raportToggle.title"}
              titleColor={"#252931"}
              titleSize={"12px"}
              label={"raportToggle.label"}
              labelColor={"#9AA8B3"}
              labelSize={"12px"}
              background={"#9aa8b3"}
              borderColor={"#9aa8b3"}
              toggleId={"toggle-switch"}
              uppercase={true}
              toggle={isChecked.all}
              setToggle={() => setIsChecked({ all: !isChecked.all })}
              name={"all"}
              setSelectedRows={setSelectedRows}
              isChecked={isChecked}
              bodyData={bodyData}
            ></Toggle>

            <TextInput
              inputClassName={
                "flex w-full h-[50px] pl-4 text-regularSize border border-[#EBEBEB] rounded-md"
              }
              placeholderLngId={"raport.group2SearchPlaceholder"}
              id={"searchInput2"}
              formData={searchValue2}
              handleChange={handleChange}
              icon={searchIcon}
            />
          </div>

          {selectedRows.length === 1 && (
            <SelectedUserField
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              setIsChecked={setIsChecked}
            ></SelectedUserField>
          )}

          <Table
            headerData={GenerateRaportTable}
            bodyData={filteredUserArray}
            setState
            setRowData
            version={4}
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            shadow={true}
          />

          <div className=" flex flex-col gap-2">
            <h3 className=" text-extraSmall text-blackPrimary">
              <FormattedMessage id="raport.datesTitle" />
            </h3>
            <div className=" flex flex-col sm:flex-row items-center gap-3">
              <DateInput
                labelLngId={"raport.fromDate"}
                inputId={"fromDate"}
                value={formData.fromDate}
                handleChange={handleChange}
                parentClassName={"w-full sm:w-1/2"}
                className={"px-3 border-[1px] rounded-md h-[50px] text-text16"}
              />

              <DateInput
                labelLngId={"raport.toDate"}
                inputId={"toDate"}
                value={formData.toDate}
                handleChange={handleChange}
                parentClassName={"w-full sm:w-1/2"}
                className={"px-3 border-[1px] rounded-md h-[50px] text-text16"}
              />
            </div>
          </div>

          <div className="next-btn">
            <ReusableBtn
              id={"back"}
              icon={leftArrowIcon}
              text={"raports.backBtn"}
              mainStyles={
                "w-[130px] h-[45px] gap-2 border border-[#999999] rounded-[30px]"
              }
              textStyles={"text-text16 text-[#999999]"}
              handleClick={handleBtnClick}
            />

            <ReusableBtn
              id={"next"}
              icon={rightArrowIcon}
              text={"raports.nextBtn"}
              mainStyles={
                "w-[130px] h-[45px] bg-redPrimary gap-2 rounded-[30px]"
              }
              textStyles={"text-text16 text-[#F8F8F8]"}
              // handleClick={handleNextBtnClick}
              // handleClick={handleNextPrevBtn}
              handleClick={handleBtnClick}
            />
          </div>
        </RaportGroup>

        <RaportGroup
          raportGroupIndex={raportGroupIndex}
          index={2}
          contentTitleLngId={"raports.createNewRaportTitle3"}
          readyToGo={true}
        >
          <div className="space-y-4">
            <OverviewComponent
              lngId={"raports.group3text1"}
              selectedValue={formData.raportName}
            ></OverviewComponent>

            <OverviewComponent
              lngId={"raports.group3text3"}
              selectedValue={formData.fromDate}
            ></OverviewComponent>
            <OverviewComponent
              lngId={"raports.group3text4"}
              selectedValue={formData.toDate}
            ></OverviewComponent>
          </div>

          <div className="next-btn">
            <ReusableBtn
              id={"back"}
              icon={leftArrowIcon}
              text={"raports.backBtn"}
              mainStyles={
                "w-[130px] h-[45px] gap-2 border border-[#999999] rounded-[30px]"
              }
              textStyles={"text-text16 text-[#999999]"}
              // handleClick={handleBackBtnClick}
              // handleClick={handleNextPrevBtn}
              handleClick={handleBtnClick}
            />
          </div>
        </RaportGroup>
      </>

      <div className="flex items-center p-4 border-t justify-center md:justify-end gap-5 ">
        <ReusableBtn
          id={"table"}
          mainStyles={
            "w-[106px] h-[45px] bg-white rounded-md border-2 border-blackPrimary"
          }
          textStyles={"text-text18"}
          text={"bulkUploads.discard"}
          // handleClick={handleBtnActions}
          handleClick={handleBtnClick}
        />

        <ReusableBtn
          // id={"create"}
          type={"submit"}
          // imgWidth={"14px"}
          // mainStyles={"w-[106px] h-[45px] bg-redPrimary rounded-md"}
          // textStyles={"text-text18 text-[#FFFFFF]"}
          mainStyles={"w-fit h-[45px] bg-redPrimary px-4 gap-2 rounded-md"}
          textStyles={"text-[#FFFFFF] text-filterBtn"}
          text={"raports.createRaportTitle"}
          // disabled={isUploading}
        />
      </div>
    </form>
  );
};

CreateNewRaport.propTypes = {
  setView: PropTypes.func,
};

export default CreateNewRaport;
