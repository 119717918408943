/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { PropTypes } from "prop-types";
import TextInput from "../../../../components/Inputs/TextInput";
import TimeInput from "../../../../components/Inputs/TimeInput";
import ReusableBtn from "../../../../components/ReusableBtn/ReusableBtn";
import { DaysOfWeek } from "../../../../constants";
import DayOfTheWeek from "./DayOfTheWeek";

const NewTimetable = ({ handleBtnActions }) => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    sunday: { from: "06:00", to: "18:00" },
    monday: { from: "06:00", to: "18:00" },
    tuesday: { from: "06:00", to: "18:00" },
    wednesday: { from: "06:00", to: "18:00" },
    thursday: { from: "06:00", to: "18:00" },
    friday: { from: "06:00", to: "18:00" },
    saturday: { from: "06:00", to: "18:00" },
  });

  const handleChange = (event, day) => {
    const { id } = event.target;

    if (id === "clear") {
      setFormData((prev) => ({
        ...prev,
        [day]: { from: "06:00", to: "18:00" },
      }));

      return;
    }

    if (id === "name" || id === "description") {
      setFormData((prev) => ({ ...prev, [id]: event.target.value }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [day]: {
          ...prev[day],
          [id]: event.target.value,
        },
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col gap-6 h-[calc(100vh-180px)]"
    >
      <div className="flex flex-col md:flex-row gap-4">
        <TextInput
          id={"name"}
          mainStyles={"w-full"}
          inputClassName={"h-[55px] bg-white rounded-md px-3 text-text16"}
          placeholderLngId={"timetablesTable.header2"}
          labelStyle={"text-text18 font-bold"}
          labelLngId={"timetablesTable.header2"}
          formData={formData.name}
          handleChange={handleChange}
        />

        <TextInput
          id={"description"}
          mainStyles={"w-full"}
          inputClassName={"h-[55px] bg-white rounded-md px-3 text-text16"}
          placeholderLngId={"timetablesTable.header3"}
          labelStyle={"text-text18 font-bold"}
          labelLngId={"timetablesTable.header3"}
          formData={formData.description}
          handleChange={handleChange}
        />
      </div>

      {DaysOfWeek.map((day) => (
        <DayOfTheWeek
          text={day.text}
          key={day.day}
          day={day.day}
          formData={formData}
          handleChange={handleChange}
        />
      ))}

      <div className="flex items-center px-4 py-6 border-t justify-center md:justify-end mt-5 gap-5">
        <ReusableBtn
          id={"table"}
          mainStyles={
            "w-[106px] h-[45px] bg-white rounded-md border-2 border-blackPrimary"
          }
          textStyles={"text-text18"}
          text={"bulkUploads.discard"}
          handleClick={handleBtnActions}
        />

        <ReusableBtn
          id={"submit"}
          type={"submit"}
          imgWidth={"14px"}
          mainStyles={"w-[106px] h-[45px] bg-redPrimary rounded-md"}
          textStyles={"text-text18 text-[#FFFFFF]"}
          text={"bulkUploads.upload"}
          //   disabled={isUploading}
        />
      </div>
    </form>
  );
};

NewTimetable.propTypes = {
  handleBtnActions: PropTypes.func,
};

export default NewTimetable;
