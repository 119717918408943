import dayjs from "dayjs";

// export function getMonth(month = dayjs().month()) {
//   month = Math.floor(month);

//   const year = dayjs().year();

//   const weekDay = dayjs(new Date(year, month, 1)).day();

//   let currentMonthCount = 0 - weekDay;

//   const daysMatrix = new Array(5).fill([]).map(() => {
//     return new Array(7).fill(null).map(() => {
//       currentMonthCount++;

//       return dayjs(new Date(year, month, currentMonthCount));
//     });
//   });

//   return daysMatrix;
// }

// import dayjs from "dayjs";

export function getMonth(month = dayjs().month()) {
  console.log("This is dayjs.month:", dayjs().month());

  month = Math.floor(month); // Ensure month is an integer

  const year = dayjs().year(); // Get the current year

  // Get the weekday of the 1st day of the current month
  const firstDayOfMonth = dayjs(new Date(year, month, 1));

  // const weekDay = firstDayOfMonth.day(); // 0 (Sunday) to 6 (Saturday)

  let currentMonthCount = 1; // Start from the 1st of the current month
  const daysInMonth = firstDayOfMonth.daysInMonth(); // Total days in the current month

  const daysMatrix = new Array(5).fill([]).map(() => {
    return new Array(7).fill(null).map(() => {
      // If currentMonthCount is still less than or equal to days in the current month, display the current month's day
      if (currentMonthCount <= daysInMonth) {
        const day = dayjs(new Date(year, month, currentMonthCount));

        currentMonthCount++;

        return day;
      } else {
        // Fill remaining days with the days of the next month
        const nextMonthDay = currentMonthCount - daysInMonth;
        const nextMonth = dayjs(new Date(year, month + 1, nextMonthDay));

        currentMonthCount++;

        return nextMonth;
      }
    });
  });

  return daysMatrix;
}
