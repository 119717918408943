import React, { useState } from "react";
import { PropTypes } from "prop-types";
import uploadIcon from "../../../../assets/icons/Panel/administration/BulkUploads/uploadIcon.svg";
import { FormattedMessage } from "react-intl";
import { CircularProgress } from "@mui/material";

const Upload = ({ title, mainStyles, handleChange, isUploading, fileName }) => {
  const [isDragging, setIsDragging] = useState(false);

  // Drag-and-Drop Handlers
  const handleDragOver = (event) => {
    event.preventDefault();

    event.stopPropagation();

    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();

    event.stopPropagation();

    setIsDragging(false);
  };

  const handleDrop = (event) => {
    // prevent default behaviour of browser opening file in a new page
    event.preventDefault();

    // focus event on the element we drop instead of the parent element
    event.stopPropagation();

    // Access files from the drag-and-drop event. This event is used for drag and drop
    const files = event.dataTransfer.files;

    if (files.length > 0) {
      // custom event object similar to the one used in handleChange
      const customEvent = {
        target: { id: "excel", files },
      };

      // Pass the custom event object to handleChange
      handleChange(customEvent);
    }
  };

  return (
    <div>
      {title && (
        <h4 className="text-extraSmall text-textSecondary p-3">
          <FormattedMessage id={title} />
        </h4>
      )}
      <label
        htmlFor="excel"
        className={`w-full ${mainStyles} bg-white border border-dashed hover:border-sky-500 ${
          isDragging && "border-sky-500"
        } rounded-md flex justify-center items-center gap-4`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {isUploading ? (
          <CircularProgress style={{ color: "#E60000" }} />
        ) : (
          <>
            <input
              className="hidden"
              type="file"
              id="excel"
              onChange={handleChange}
            />
            <img src={uploadIcon} alt={uploadIcon} />
            <div className=" text-darkGray ">
              <p className="text-text16">
                <FormattedMessage
                  id={fileName ? fileName : "bulkUploads.text4"}
                />
              </p>
              {!fileName && (
                <p className="text-extraSmall">
                  <FormattedMessage id="bulkUploads.text5" />
                </p>
              )}
            </div>
          </>
        )}
      </label>
    </div>
  );
};

Upload.propTypes = {
  title: PropTypes.string,
  mainStyles: PropTypes.string,
  fileName: PropTypes.string,
  isUploading: PropTypes.bool,
  handleChange: PropTypes.func,
};

export default Upload;
