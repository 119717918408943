/* eslint-disable complexity */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { FilterItems } from "../../../../constants";
import TextInput from "../../../../components/Inputs/TextInput";
import Checkbox from "../../../../components/Inputs/Checkbox";
import arrowForward from "../../../../assets/icons/Panel/arrow_forward_icon_gray.svg";
import Accordation from "./accordation/Accordation";
import ReusableBtn from "../../../../components/ReusableBtn/ReusableBtn";

const MapFilter = ({ toggleFunction }) => {
  const [accordationIndex, setAccordationIndex] = useState(1);

  const [subAccordIndex, setSubAccordIndex] = useState([
    true,
    true,
    true,
    true,
  ]);

  const [formData, setFormData] = useState({
    asset: "",
    PI: "",
    serial: "",
    assetType: "car",
  });

  const handleReset = () => {
    setFormData({ asset: "", PI: "", serial: "" });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    console.log(formData);
  };

  const handleChange = (event) => {
    const { id, value } = event.target;

    if (
      event.target.name === "all" ||
      event.target.name === "ambulance" ||
      event.target.name === "beton" ||
      event.target.name === "bicycle" ||
      event.target.name === "boat" ||
      event.target.name === "bus" ||
      event.target.name === "car"
      // event.target.name === "all" ||
      // event.target.name === "ambulance" ||
      // event.target.name === "beton" ||
      // event.target.name === "bicycle" ||
      // event.target.name === "boat" ||
      // event.target.name === "bus" ||
      // event.target.name === "car"
    ) {
      setFormData((prevValues) => ({
        ...prevValues,
        assetType: event.target.name,
      }));
    }

    if (id === "asset" || id === "PI" || id === "serial") {
      setFormData((prevValues) => ({
        ...prevValues,
        [id]: value,
      }));
    }
  };

  const handleAccordation = (param) => {
    if (param === 1) {
      if (accordationIndex === 1) {
        setAccordationIndex(null);
      } else {
        setAccordationIndex(1);
      }
    } else if (param === 2) {
      if (accordationIndex === 2) {
        setAccordationIndex(null);
      } else {
        setAccordationIndex(2);
      }
    } else if (param === 3) {
      if (accordationIndex === 3) {
        setAccordationIndex(null);
      } else {
        setAccordationIndex(3);
      }
    } else if (param === 4) {
      if (accordationIndex === 4) {
        setAccordationIndex(null);
      } else {
        setAccordationIndex(4);
      }
    }
  };

  const handleSubAccordation = (param) => {
    setSubAccordIndex((prevValues) => {
      const newState = [...prevValues];

      newState[param] = !newState[param];

      return newState;
    });
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="w-full h-full flex flex-col justify-between overflow-x-hidden mapFilter"
    >
      <div className="flex justify-between items-center mb-2">
        <span className="text-[20px] text-blackPrimary font-albertSansMedium">
          {FilterItems.title}
        </span>
        <img
          src={FilterItems.closingIcon}
          onClick={toggleFunction}
          className="cursor-pointer active:opacity-75"
        ></img>
      </div>

      <div className="flex flex-col gap-3 pt-2 flex-1 ">
        <TextInput
          inputClassName={
            " w-full h-[46px] max-h-[46px] px-5 text-regularSize border border-[#EAF1F4] rounded-md"
          }
          placeholderLngId={"filter.placeholder"}
          id={"asset"}
          formData={formData.asset}
          handleChange={handleChange}
          labelStyle={"text-filterInputLabel text-blackPrimary font-bold"}
          labelLngId={"filter.asset"}
        />

        <TextInput
          inputClassName={
            " w-full h-[46px] max-h-[46px] px-5 text-regularSize border border-[#EAF1F4] rounded-md"
          }
          placeholderLngId={"filter.placeholder"}
          id={"PI"}
          formData={formData.PI}
          handleChange={handleChange}
          labelStyle={"text-filterInputLabel text-blackPrimary font-bold"}
          labelLngId={"filter.pi"}
        />

        <TextInput
          inputClassName={
            " w-full h-[46px] max-h-[46px] px-5 text-regularSize border border-[#EAF1F4] rounded-md"
          }
          placeholderLngId={"filter.placeholder"}
          id={"serial"}
          formData={formData.serial}
          handleChange={handleChange}
          labelStyle={"text-filterInputLabel text-blackPrimary font-bold"}
          labelLngId={"filter.serial"}
        />

        <div className="flex flex-col gap-3 mt-3">
          <Accordation
            textLngId={"filter.accordion1"}
            onClick={() => handleAccordation(1)}
            icon={arrowForward}
            index={accordationIndex}
            identifier={1}
          >
            <div className="flex items-center gap-2">
              <img
                onClick={() => handleSubAccordation(0)}
                src={arrowForward}
                style={{
                  cursor: "pointer",
                  marginBottom: "4px",
                  transform: subAccordIndex
                    ? "rotate(90deg)"
                    : "rotate(-90deg)",
                  transition: "transform",
                  transitionDelay: ".1s",
                  transitionTimingFunction: "ease-in-out",
                  transitionDuration: ".1s",
                }}
              ></img>
              <Checkbox
                labelLngId={"filter.checkbox1"}
                name={"all"}
                checked={formData.assetType === "all"}
                handleChange={handleChange}
                labelSize={"text-regularSize"}
                labelColor={"text-textSecondary"}
              />
            </div>

            {subAccordIndex[0] === true && (
              <div className="pl-7">
                <Checkbox
                  labelLngId={"filter.checkbox2"}
                  name={"ambulance"}
                  checked={
                    formData.assetType === "ambulance" ||
                    formData.assetType === "all"
                  }
                  handleChange={handleChange}
                  labelSize={"text-regularSize"}
                  labelColor={"text-textSecondary"}
                />

                <Checkbox
                  labelLngId={"filter.checkbox3"}
                  name={"beton"}
                  checked={
                    formData.assetType === "beton" ||
                    formData.assetType === "all"
                  }
                  handleChange={handleChange}
                  labelSize={"text-regularSize"}
                  labelColor={"text-textSecondary"}
                />

                <Checkbox
                  labelLngId={"filter.checkbox4"}
                  name={"bicycle"}
                  checked={
                    formData.assetType === "bicycle" ||
                    formData.assetType === "all"
                  }
                  handleChange={handleChange}
                  labelSize={"text-regularSize"}
                  labelColor={"text-textSecondary"}
                />

                <Checkbox
                  labelLngId={"filter.checkbox5"}
                  name={"boat"}
                  checked={
                    formData.assetType === "boat" ||
                    formData.assetType === "all"
                  }
                  handleChange={handleChange}
                  labelSize={"text-regularSize"}
                  labelColor={"text-textSecondary"}
                />

                <Checkbox
                  labelLngId={"filter.checkbox6"}
                  name={"bus"}
                  checked={
                    formData.assetType === "bus" || formData.assetType === "all"
                  }
                  handleChange={handleChange}
                  labelSize={"text-regularSize"}
                  labelColor={"text-textSecondary"}
                />

                <Checkbox
                  labelLngId={"filter.checkbox7"}
                  name={"car"}
                  checked={
                    formData.assetType === "car" || formData.assetType === "all"
                  }
                  handleChange={handleChange}
                  labelSize={"text-regularSize"}
                  labelColor={"text-textSecondary"}
                />
              </div>
            )}
          </Accordation>

          <Accordation
            textLngId={"filter.accordion2"}
            onClick={() => handleAccordation(2)}
            icon={arrowForward}
            index={accordationIndex}
            identifier={2}
          >
            <div className="flex items-center gap-2">
              <img
                onClick={() => handleSubAccordation(1)}
                src={arrowForward}
                style={{
                  cursor: "pointer",
                  marginBottom: "4px",
                  transform: subAccordIndex
                    ? "rotate(90deg)"
                    : "rotate(-90deg)",
                  transition: "transform",
                  transitionDelay: ".1s",
                  transitionTimingFunction: "ease-in-out",
                  transitionDuration: ".1s",
                }}
              ></img>
              <Checkbox
                labelLngId={"filter.checkbox1"}
                name={"all"}
                checked={formData.assetType === "all"}
                handleChange={handleChange}
                labelSize={"text-regularSize"}
                labelColor={"text-textSecondary"}
              />
            </div>

            {subAccordIndex[1] && (
              <div className="pl-7">
                <Checkbox
                  labelLngId={"filter.checkbox2"}
                  name={"ambulance"}
                  checked={
                    formData.assetType === "ambulance" ||
                    formData.assetType === "all"
                  }
                  handleChange={handleChange}
                  labelSize={"text-regularSize"}
                  labelColor={"text-textSecondary"}
                />

                <Checkbox
                  labelLngId={"filter.checkbox3"}
                  name={"beton"}
                  checked={
                    formData.assetType === "beton" ||
                    formData.assetType === "all"
                  }
                  handleChange={handleChange}
                  labelSize={"text-regularSize"}
                  labelColor={"text-textSecondary"}
                />

                <Checkbox
                  labelLngId={"filter.checkbox4"}
                  name={"bicycle"}
                  checked={
                    formData.assetType === "bicycle" ||
                    formData.assetType === "all"
                  }
                  handleChange={handleChange}
                  labelSize={"text-regularSize"}
                  labelColor={"text-textSecondary"}
                />

                <Checkbox
                  labelLngId={"filter.checkbox5"}
                  name={"boat"}
                  checked={
                    formData.assetType === "boat" ||
                    formData.assetType === "all"
                  }
                  handleChange={handleChange}
                  labelSize={"text-regularSize"}
                  labelColor={"text-textSecondary"}
                />

                <Checkbox
                  labelLngId={"filter.checkbox6"}
                  name={"bus"}
                  checked={
                    formData.assetType === "bus" || formData.assetType === "all"
                  }
                  handleChange={handleChange}
                  labelSize={"text-regularSize"}
                  labelColor={"text-textSecondary"}
                />

                <Checkbox
                  labelLngId={"filter.checkbox7"}
                  name={"car"}
                  checked={
                    formData.assetType === "car" || formData.assetType === "all"
                  }
                  handleChange={handleChange}
                  labelSize={"text-regularSize"}
                  labelColor={"text-textSecondary"}
                />
              </div>
            )}
          </Accordation>

          <Accordation
            textLngId={"filter.accordion3"}
            onClick={() => handleAccordation(3)}
            icon={arrowForward}
            index={accordationIndex}
            identifier={3}
          >
            <div className="flex items-center gap-2">
              <img
                onClick={() => handleSubAccordation(3)}
                src={arrowForward}
                style={{
                  cursor: "pointer",
                  marginBottom: "4px",
                  transform: subAccordIndex
                    ? "rotate(90deg)"
                    : "rotate(-90deg)",
                  transition: "transform",
                  transitionDelay: ".1s",
                  transitionTimingFunction: "ease-in-out",
                  transitionDuration: ".1s",
                }}
              ></img>
              <Checkbox
                labelLngId={"filter.checkbox1"}
                name={"all"}
                checked={formData.assetType === "all"}
                handleChange={handleChange}
                labelSize={"text-regularSize"}
                labelColor={"text-textSecondary"}
              />
            </div>

            {subAccordIndex[1] && (
              <div className="pl-7">
                <Checkbox
                  labelLngId={"filter.checkbox2"}
                  name={"ambulance"}
                  checked={
                    formData.assetType === "ambulance" ||
                    formData.assetType === "all"
                  }
                  handleChange={handleChange}
                  labelSize={"text-regularSize"}
                  labelColor={"text-textSecondary"}
                />

                <Checkbox
                  labelLngId={"filter.checkbox3"}
                  name={"beton"}
                  checked={
                    formData.assetType === "beton" ||
                    formData.assetType === "all"
                  }
                  handleChange={handleChange}
                  labelSize={"text-regularSize"}
                  labelColor={"text-textSecondary"}
                />

                <Checkbox
                  labelLngId={"filter.checkbox4"}
                  name={"bicycle"}
                  checked={
                    formData.assetType === "bicycle" ||
                    formData.assetType === "all"
                  }
                  handleChange={handleChange}
                  labelSize={"text-regularSize"}
                  labelColor={"text-textSecondary"}
                />

                <Checkbox
                  labelLngId={"filter.checkbox5"}
                  name={"boat"}
                  checked={
                    formData.assetType === "boat" ||
                    formData.assetType === "all"
                  }
                  handleChange={handleChange}
                  labelSize={"text-regularSize"}
                  labelColor={"text-textSecondary"}
                />

                <Checkbox
                  labelLngId={"filter.checkbox6"}
                  name={"bus"}
                  checked={
                    formData.assetType === "bus" || formData.assetType === "all"
                  }
                  handleChange={handleChange}
                  labelSize={"text-regularSize"}
                  labelColor={"text-textSecondary"}
                />

                <Checkbox
                  labelLngId={"filter.checkbox7"}
                  name={"car"}
                  checked={
                    formData.assetType === "car" || formData.assetType === "all"
                  }
                  handleChange={handleChange}
                  labelSize={"text-regularSize"}
                  labelColor={"text-textSecondary"}
                />
              </div>
            )}
          </Accordation>

          <Accordation
            textLngId={"filter.accordion4"}
            onClick={() => handleAccordation(4)}
            icon={arrowForward}
            index={accordationIndex}
            identifier={4}
          >
            <div className="flex items-center gap-2">
              <img
                onClick={() => handleSubAccordation(4)}
                src={arrowForward}
                style={{
                  cursor: "pointer",
                  marginBottom: "4px",
                  transform: subAccordIndex
                    ? "rotate(90deg)"
                    : "rotate(-90deg)",
                  transition: "transform",
                  transitionDelay: ".1s",
                  transitionTimingFunction: "ease-in-out",
                  transitionDuration: ".1s",
                }}
              ></img>
              <Checkbox
                labelLngId={"filter.checkbox1"}
                name={"all"}
                checked={formData.assetType === "all"}
                handleChange={handleChange}
                labelSize={"text-regularSize"}
                labelColor={"text-textSecondary"}
              />
            </div>
          </Accordation>
        </div>
      </div>

      <div className="flex justify-between items-center pt-6">
        <ReusableBtn
          type={"reset"}
          text={FilterItems.btn1}
          mainStyles={"w-full max-w-[150px] h-[45px] bg-darkGray rounded-md"}
          textStyles={"text-[#FFFFFF] text-filterBtn"}
          handleClick={handleReset}
        />

        <ReusableBtn
          type={"submit"}
          text={FilterItems.btn2}
          mainStyles={"w-full max-w-[150px] h-[45px] bg-redPrimary rounded-md"}
          textStyles={"text-[#FFFFFF] text-filterBtn"}
          // handleClick
        />
      </div>
    </form>
  );
};

MapFilter.propTypes = {
  toggleFunction: PropTypes.func,
};

export default MapFilter;
