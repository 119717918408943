/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Table from "../Administration/Components/table/Table";
import PageTitle from "../../components/Layout/PageTitle";
import { NetworkTable } from "../../constants";

const Network = () => {
  const [view, setView] = useState("table");
  const [rowData, setRowData] = useState(null);

  const bodyData = [
    {
      id: 0,
      data: [
        {
          id: 0,
          row: "AA 091 OH",
        },
        {
          id: 1,
          row: "Makina/Car",
        },
        {
          id: 2,
          row: "FMB130",
        },
        {
          id: 3,
          row: "-",
        },
        {
          id: 4,
          row: "359633108103750",
        },
        {
          id: 5,
          row: [39, 48, 18],
        },
        {
          id: 6,
          row: "-",
        },
        {
          id: 7,
          row: "Krujë, Durrës, 1502, ALB",
        },
        {
          id: 8,
          row: ["07/0224", "13:30"],
        },
      ],
    },
  ];

  return (
    <div className="pageLayout">
      <div className="w-full flex flex-col gap-2 lg:gap-0 lg:flex-row lg:justify-between lg:items-center bg-mainBg pt-5 z-10">
        <PageTitle
          titleLngId={"network.pageTitle"}
          subTitleLngId={"network.subtitle"}
          subTitle2LngId={"network.pageTitle"}
          state={view}
          setState={() => setView("table")}
        ></PageTitle>
      </div>

      {view === "table" && (
        <Table
          version={5}
          headerData={NetworkTable}
          bodyData={bodyData}
          setState={setView}
          setRowData={setRowData}
        />
      )}
    </div>
  );
};

export default Network;
