import React from "react";
import { PropTypes } from "prop-types";
import { FormattedMessage } from "react-intl";

import downArrow from "../../assets/icons/Panel/downArrow.svg";

const SelectInput = ({
  id,
  name,
  className,
  options,
  optgroup,
  onChange,
  labelLngId,
  labelStyles,
}) => {
  return (
    <div className={`flex flex-col py-1 relative ${className}`}>
      {labelLngId && (
        <label htmlFor={id} className={labelStyles}>
          <FormattedMessage id={labelLngId} />
        </label>
      )}

      <img
        src={downArrow}
        alt={downArrow}
        className="absolute right-0 top-0 bottom-0 my-auto bg-white p-3 pointer-events-none"
      />

      <select
        id={id}
        name={name}
        type="select"
        className={"w-full h-full outline-none text-sm"}
        onChange={onChange}
      >
        {optgroup ? (
          <optgroup label={optgroup} aria-placeholder={optgroup}>
            {options.map((item) => (
              <option
                key={item.label}
                value={item.value}
                disabled={item.disabled}
                selected={item.selected}
              >
                {item.label}
              </option>
            ))}
          </optgroup>
        ) : (
          options.map((item) => (
            <option
              key={item.label}
              value={item.value}
              disabled={item.disabled}
              selected={item.selected}
            >
              {item.label}
            </option>
          ))
        )}
      </select>
    </div>
  );
};

SelectInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.Object,
  optgroup: PropTypes.string,
  labelLngId: PropTypes.string,
  labelStyles: PropTypes.string,
  onChange: PropTypes.func,
};

export default SelectInput;
